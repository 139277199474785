import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getAjaxRequest } from "../common";
import FileUploadField from "../commonComponents/FileUploadField";
import Spinner from "../commonComponents/Spinner";
import config, { message } from "../config";
import { addNotification } from "../store/notificationSlice";

export default function NewDishForm({ dishCategory, reRender, dishDetails }) {
  const [mainImage, setMainImage] = useState(
    "/files/misc/image-placeholder.png"
  );
  const [submitClicked, setSubmitClicked] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useDispatch();
  const createOrUpdateDish = async () => {
    setSubmitClicked(true);
    trigger();
    if (!isValid) return;
    try {
      setShowSpinner(true);
      await getAjaxRequest(dishDetails ? "updateDish" : "addNewDish", {
        dishCategoryId: dishCategory._id,
        dishDetails: getValues(),
        ...(dishDetails ? {dishId: dishDetails._id} : {})
      });
      reRender();
      dispatch(
        addNotification({
          type: "success",
          message: dishDetails ? "Dish Updated Successfully" : "New Dish Added Successfully",
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        addNotification({
          type: "error",
          message: message.someErrorOccurred,
        })
      );
    } finally {
      setShowSpinner(false);
    }
  };
  // features need to be added update, 
  useEffect(() => {
    if (dishDetails) {
      setMainImage(`${config.baseResources}/${dishDetails.image}`);
      setValue('name', dishDetails.name);
      setValue('price', dishDetails.price);
      setValue('discountedPrice', dishDetails.discountedPrice);
      setValue('description', dishDetails.description);
      setValue('image', dishDetails.image);
    }
  }, []);
  const {
    register,
    getValues,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm({ mode: "onBlur" });

  register("image", { required: true });

  const handleMainImageUpload = (event) => {
    setMainImage(
      (event.target.value.length &&
        `${config.baseResources}/${event.target.value[0].fileName}`) ||
        "/files/misc/image-placeholder.png"
    );
    setValue("image", event.target.value[0]?.fileName);
    trigger("image");
  };
  return (
    <Spinner showSpinner={showSpinner}>
      <div className="full-width">
        <div
          style={{ backgroundImage: `url("${mainImage}")` }}
          className="container-box cover-image popup-dish-cover"
        ></div>
        <div className="gap-5"></div>
        <div className="form-field">
          <FileUploadField
            label="Upload New Dish Image*"
            name="image"
            singleUpload={true}
            fileType={["jpg", "jpeg", "png"]}
            changeEvent={handleMainImageUpload}
          />
          {submitClicked && !getValues().image && (
            <div className="error-message">Please Select a Dish image</div>
          )}
        </div>
        <div className="padding-5">
          <div className="form-field">
            <input
              placeholder="Dish Name*"
              type="text"
              className="h4-field input-field"
              {...register("name", { required: true })}
            />
            {errors.name && (
              <div className="error-message">Please Enter Dish Name</div>
            )}
          </div>
          <div className="vertical-center">
            <div style={{ width: "50%" }} className="form-field">
              <input
                placeholder="Price*"
                type="number"
                className="h3-field input-field medium-input"
                {...register("discountedPrice", { required: true })}
              />
              {errors.discountedPrice && (
                <div className="error-message">Please Enter Price</div>
              )}
            </div>
            <div className="gap-x-5"></div>
            <div style={{ width: "30%" }} className="form-field">
              <input
                type="number"
                className="discounted-price-field input-field medium-input"
                placeholder="Inflated Price"
                {...register("price", { value: "" })}
              />
            </div>
            <div className="gap-x-5"></div>
          </div>
          <div className="form-field">
            <textarea
              style={{ minHeight: "120px" }}
              placeholder="Dish Description*"
              className="font-2 input-field medium-input full-width"
              {...register("description", { required: true })}
            ></textarea>
            {errors.description && (
              <div className="error-message">Please Enter Dish Description</div>
            )}
          </div>
          <div className="align-right">
            <button
              onClick={createOrUpdateDish}
              className="double-line-button green-button font-3"
            >
              Confirm
            </button>
          </div>
          <div className="gap"></div>
          <div className="gap"></div>
        </div>
      </div>
    </Spinner>
  );
}
