import React from "react";

export default function ToggleCheckbox({ value, onChange }) {
  return (
    <div>
      <label className="switch">
        <input
          checked={value || false}
          onChange={(e) => onChange(e)}
          type="checkbox"
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
}
