import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "../store/notificationSlice";
import { message } from "../config";
import { commonMethods, getAjaxRequest } from "../common";
import TableWidget from "./TableWidget";
import {
  setBillingOrderList,
  setRestaurantDetails,
} from "../store/billingCenterSlice";
import { billingCenterSocket } from "../socket";
import Popup from "./Popup";
import ChargesOrDiscountForm from "./ChargesOrDiscountForm";
import Spinner from "../commonComponents/Spinner";
import BillingComponent from "./BillingComponent";

function BillingCenter() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [tableSelected, setTableSeleced] = useState(null);
  const [popupType, setPopupType] = useState("");
  const [updateChargeDiscount, setUpdateChargeDiscount] = useState(null);
  const [tableBillOrderList, setTableBillOrderList] = useState([]);

  useEffect(() => {
    billingCenterSocket.connect();
    const listener = (data) => {
      dispatch(setBillingOrderList(data));
    };
    billingCenterSocket.on("orderUpdate", listener);
    return () => {
      billingCenterSocket.off("orderUpdate", listener);
      billingCenterSocket.disconnect();
    };
  }, [dispatch]);
  const fetchBillingDetails = useCallback(async () => {
    try {
      const data = await getAjaxRequest("getRestaurantDetails", {}, setLoading);
      dispatch(setRestaurantDetails(data.data));
    } catch (error) {
      console.log(error);
      dispatch(
        addNotification({
          type: "error",
          message: message.someErrorOccurred,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    fetchBillingDetails();
  }, [fetchBillingDetails]);

  const restaurantDetails = useSelector(
    (state) => state.billingCenter.restaurantDetails
  );
  const billingOrderList = useSelector(
    (state) => state.billingCenter.billingOrderList
  );

  const billingTableList = useMemo(() => {
    const temp = [];
    return billingOrderList.map((i) => ({
      tableCategory: {
        category: i.tableCategory,
        _id: i.tableCategoryId,
      },
      tableNumber: i.tableNo,
    })).filter(i => {
      if (temp.includes(`${i.tableCategory._id}-${i.tableNumber}`)) return false;
      else {
        temp.push(`${i.tableCategory._id}-${i.tableNumber}`);
        return true;
      }
    });
  }, [billingOrderList]);

  useEffect(() => {
    const temp =
      tableSelected &&
      billingOrderList.filter(
        (i) =>
          i.tableCategoryId === tableSelected.tableCategory._id &&
          i.tableNo === tableSelected.tableNumber
      );
    setTableBillOrderList(temp);
  }, [billingOrderList, tableSelected]);

  const tableClick = useCallback((table) => {
    console.log(table);
    setTableSeleced(table);
    setPopupType("tableSelected");
  }, []);

  const getPopup = () => {
    switch (popupType) {
      case "addCharge":
        return (
          <Popup closePopup={() => setPopupType("")}>
            <div style={{ padding: "5px 8px" }}>
              <ChargesOrDiscountForm
                closePopup={() => {
                  setPopupType("");
                  fetchBillingDetails();
                }}
              />
            </div>
          </Popup>
        );
      case "updateChargeDiscount":
        return (
          <Popup
            closePopup={() => {
              setPopupType("");
              setUpdateChargeDiscount(null);
            }}
          >
            <div style={{ padding: "5px 8px" }}>
              <ChargesOrDiscountForm
                formData={updateChargeDiscount}
                closePopup={() => {
                  setPopupType("");
                  setUpdateChargeDiscount(null);
                  fetchBillingDetails();
                }}
              />
            </div>
          </Popup>
        );
      case "tableSelected":
        return (
          tableSelected && (
            <Popup
              closePopup={() => {
                setTableSeleced(null);
                setPopupType("");
              }}
            >
              <div style={{ padding: "10px" }}>
                <h4>{`Bill ${tableSelected.tableCategory.category} ${tableSelected.tableNumber}`}</h4>
                <div className="gap-5"></div>
                <BillingComponent
                  tableBillOrderList={tableBillOrderList}
                  billingItemList={restaurantDetails.data.billing}
                  restaurantDetails={restaurantDetails}
                  tableSelected={tableSelected}
                  type={"billingCenter"}
                  closePopup={() => {
                    setPopupType("");
                    setTableSeleced(null);
                  }}
                />
              </div>
            </Popup>
          )
        );
      default:
        break;
    }
  };

  return (
    <Spinner showSpinner={loading}>
      <div className="full-width container-box">
        {getPopup()}
        <div className="default-width">
          <div>
            {restaurantDetails && (
              <TableWidget
                tableDetails={restaurantDetails.tables}
                tableClick={tableClick}
              />
            )}
            <div>
              <h4>Live Orders</h4>
              <div className="gap-5"></div>
              <div className="flex-wrap vertical-center table-categories-container">
                {billingTableList.map((i) => (
                  <div
                    key={`${i.tableCategory._id}-${i.tableNumber}`}
                    className="table-category align-center clickable font-4 container-box"
                    onClick={() => tableClick(i)}
                  >
                    <div>{i.tableCategory.category}</div>
                    <div>{i.tableNumber}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="gap-5"></div>
            <div className="same-line">
              <h4>Charges and Discounts</h4>
              <div className="gap-x-5"></div>
              <button
                onClick={() => setPopupType("addCharge")}
                className="icon-background plus-icon-button"
              ></button>
            </div>
            <div className="gap-5"></div>
            <div>
              {restaurantDetails &&
                restaurantDetails.data.billing.map((i) => (
                  <div
                    key={i._id}
                    onClick={() => {
                      setPopupType("updateChargeDiscount");
                      setUpdateChargeDiscount(i);
                    }}
                    className={`same-line chargeDiscountContainer clickable ${
                      !i.active ? "inactiveChargeDiscount" : ""
                    }`}
                  >
                    <div>{i.name}</div>
                    <div className="font-4 font-bold">{`${
                      i.type === "Charge" ? "+" : "-"
                    } ${
                      i.amountType === "Fixed"
                        ? commonMethods.getCurrency(restaurantDetails.currency)
                        : ""
                    }${i.amount}${
                      i.amountType === "Percentage" ? "%" : ""
                    }`}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </Spinner>
  );
}

export default BillingCenter;
