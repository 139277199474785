import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { getAjaxRequest } from "../common";
import { useDispatch } from "react-redux";
import { addNotification } from "../store/notificationSlice";
import { message } from "../config";
import Spinner from "../commonComponents/Spinner";
import TableWidget from "./TableWidget";
import { actionCenterSocket } from "../socket";
import Popup from "./Popup";
import { useNavigate } from "react-router-dom";
import { useBeforeMount } from "../commonComponents/commonHook";
import { removeNavigationList, setNavigationList } from "../store/sidebarSlice";
import ConfirmationPopup from "./ConfirmationPopup";

export default function ActionCenter() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [restaurantDetails, setRestaurantDetails] = useState(null);
  const [allOrders, setAllOrders] = useState([]);
  const [selectedLiveOrder, setSelectedLiveOrder] = useState(null);
  const [deliveredClickedOrder, setDeliveredClickedOrder] = useState(null);
  const [attendTableList, setAttendTableList] = useState([]);
  const [selectedAttendTable, setSelectedAttendTable] = useState(null);

  const componentName = useRef("actionCenter");

  useEffect(() => {
    (async () => {
      try {
        const res = await getAjaxRequest(
          "getRestaurantDetails",
          {},
          setLoading
        );
        setRestaurantDetails(res.data);
      } catch (error) {
        console.log(error);
        dispatch(
          addNotification({
            type: "error",
            message: message.someErrorOccurred,
          })
        );
      }
    })();
  }, [dispatch]);

  const [mount] = useBeforeMount(() => {
    dispatch(
      setNavigationList({
        navList: [
          {
            scroll: true,
            url: "#tableCategories",
            text: message.navListTableCategories,
            type: "sub",
          },
          {
            scroll: true,
            url: "#attendTable",
            text: message.attendTable,
            type: "sub",
          },
          {
            scroll: true,
            url: "#liveOrders",
            text: message.navListLiveOrders,
            type: "sub",
          },
          {
            scroll: true,
            url: "#confirmedOrders",
            text: message.navListConfirmedOrders,
            type: "sub",
          },
        ],
        componentName: componentName.current,
        topNav: message.navListActionCenter,
      })
    );
    return () => dispatch(removeNavigationList(componentName.current));
  });

  const getLiveOrders = useMemo(() => {
    return allOrders.filter((i) => i.orderState === "Ordered");
  }, [allOrders]);

  const getConfirmedOrders = useMemo(() => {
    return allOrders.filter((i) => i.orderState === "Confirmed");
  }, [allOrders]);

  useEffect(() => {
    const listener = (data) => {
      console.log(data);
      setAllOrders(data);
    };
    const attendTableListener = (data) => {
      console.log(data);
      setAttendTableList(data);
    };
    actionCenterSocket.connect();
    actionCenterSocket.on("orderUpdate", listener);
    actionCenterSocket.on("attendTable", attendTableListener);
    return () => {
      actionCenterSocket.off("orderUpdate", listener);
      actionCenterSocket.off("attendTable", attendTableListener);
      actionCenterSocket.disconnect();
    };
  }, []);

  const tableClickEvent = useCallback((tableObject) => {
    setSelectedLiveOrder({
      tableCategoryId: tableObject.tableCategory._id,
      tableNo: tableObject.tableNumber,
    });
  }, []);

  const orderClick = useCallback(
    (id) => {
      const { tableCategoryId, tableNo } = allOrders.find((i) => i._id === id);
      setSelectedLiveOrder({ tableCategoryId, tableNo });
    },
    [allOrders, setSelectedLiveOrder]
  );

  const liveOrdersPopupList = useMemo(() => {
    if (selectedLiveOrder === null) return [];
    return getLiveOrders.filter(
      (i) =>
        i.tableCategoryId === selectedLiveOrder.tableCategoryId &&
        i.tableNo === selectedLiveOrder.tableNo
    );
  }, [selectedLiveOrder, getLiveOrders]);

  const removeLiveOrder = (id1, id2) => {
    const orderObject = allOrders.find((i) => i._id === id1);
    const tempList = [...orderObject.orderDishesList];
    const orderIndex = tempList.findIndex((i) => i._id === id2);
    tempList.splice(orderIndex, 1);
    const newObject = {
      ...orderObject,
      orderDishesList: tempList,
    };
    actionCenterSocket.emit("orderUpdate", newObject);
  };

  const confirmOrder = useCallback(() => {
    actionCenterSocket.emit(
      "confirmOrder",
      liveOrdersPopupList.map((i) => i._id),
      () => setSelectedLiveOrder(null)
    );
  }, [liveOrdersPopupList]);

  const selectedLiveOrderTable = useMemo(() => {
    if (!restaurantDetails || !selectedLiveOrder) return {};
    const table = restaurantDetails.tables.find(
      (i) => i._id === selectedLiveOrder.tableCategoryId
    );
    return table;
  }, [selectedLiveOrder, restaurantDetails]);

  const deliveredClick = useCallback((i, j) => {
    setDeliveredClickedOrder({ order: i, orderItem: j });
  }, []);

  const confirmDelivered = useCallback(() => {
    actionCenterSocket.emit("confirmDelivered", [
      deliveredClickedOrder.order._id,
      deliveredClickedOrder.orderItem._id,
    ]);
  }, [deliveredClickedOrder]);

  const attendTable = useCallback((data) => {
    actionCenterSocket.emit("attendTable", data);
  }, []);

  return (
    mount && (
      <Spinner showSpinner={loading} takeHeight>
        {selectedLiveOrder ? (
          <Popup closePopup={() => setSelectedLiveOrder(null)}>
            <div className="liveOrdersContainer" style={{ padding: "5px 8px" }}>
              <h4>{`Orders ${selectedLiveOrderTable.category} ${selectedLiveOrder.tableNo}`}</h4>
              <div className="gap"></div>
              {liveOrdersPopupList.map((i) => (
                <div key={i._id} className="actionCenterOrders">
                  {i.orderDishesList.map((j) => (
                    <div
                      style={{ margin: "2px" }}
                      className="align-corner"
                      key={j._id}
                    >
                      <div>{`• ${j.dishName} x ${j.quantity}`}</div>
                      <div style={{ fontSize: "20px" }}>
                        <button
                          onClick={() => removeLiveOrder(i._id, j._id)}
                          style={{ color: "#d32f2f" }}
                        >
                          &#x2716;
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
              <div className="gap"></div>
              <div className="align-corner font-3">
                <div></div>
                <div className="same-line">
                  <button
                    onClick={() => {
                      navigate(
                        `/restaurant/${restaurantDetails._id}/${selectedLiveOrder.tableCategoryId}/${selectedLiveOrder.tableNo}`
                      );
                    }}
                    className="double-line-button blue-button"
                  >
                    Add Order
                  </button>
                  <div className="gap-x-10"></div>
                  {!!liveOrdersPopupList.length && (
                    <button
                      onClick={confirmOrder}
                      className="double-line-button green-button"
                    >
                      Confirm Order
                    </button>
                  )}
                </div>
              </div>
              <div className="gap"></div>
            </div>
          </Popup>
        ) : null}
        {!!deliveredClickedOrder && (
          <ConfirmationPopup
            closePopup={() => {
              setDeliveredClickedOrder(null);
            }}
            confirmCall={confirmDelivered}
          >
            <div className="font-4" style={{ textAlign: "center" }}>
              <div>{`Table: ${deliveredClickedOrder.order.tableCategory} ${deliveredClickedOrder.order.tableNo}`}</div>
              <div>{`Order: ${deliveredClickedOrder.orderItem.dishName} x ${deliveredClickedOrder.orderItem.quantity}`}</div>
              <div>Will be marked delivered.</div>
            </div>
          </ConfirmationPopup>
        )}
        {restaurantDetails && (
          <div className="container-box">
            <div id="tableCategories" className="default-width">
              <TableWidget
                tableDetails={restaurantDetails.tables}
                type={"actionCenter"}
                tableClick={tableClickEvent}
              />
              <div className="gap-5"></div>
              <div className="attendTableContainer">
                <h4 id="attendTable">Attend Table</h4>
                <div className="gap-5"></div>
                {!!attendTableList.length && (
                  <div className="ordersContainer font-4 same-line">
                    {attendTableList.map((i, ind) => (
                      <div
                        key={i._id}
                        className="attendTableItem align-center container-box table-category clickable"
                        onClick={() => setSelectedAttendTable(i)}
                      >
                        {selectedAttendTable?._id !== i._id ? (
                          <>
                            <div>{i.category}</div>
                            <div>{i.tableNo}</div>
                          </>
                        ) : (
                          <button
                            onClick={() => attendTable(i)}
                            className="attendButton font-2"
                          >
                            Attend
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="gap"></div>
              <div className="liveOrdersContainer">
                <h4 id="liveOrders">Live Orders</h4>
                <div className="gap-5"></div>
                <div className="ordersContainer">
                  {getLiveOrders.map((i, ind1) => (
                    <div
                      key={i._id}
                      className="actionCenterOrders clickable"
                      onClick={() => orderClick(i._id)}
                    >
                      <h5>{`Table ${i.tableCategory} ${i.tableNo}`}</h5>
                      <div className="gap-5"></div>
                      {i.orderDishesList.map((j, ind2) => (
                        <div
                          className="font-2"
                          key={j._id}
                        >{`• ${j.dishName} x ${j.quantity}`}</div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div className="gap"></div>
              <div className="confirmOrdersContainer">
                <h4 id="confirmedOrders">Confirmed Orders</h4>
                <div className="gap-5"></div>
                <div className="ordersContainer">
                  {getConfirmedOrders.map((i, ind) => (
                    <div key={i._id} className="actionCenterOrders">
                      <h5>{`Table ${i.tableCategory} ${i.tableNo}`}</h5>
                      <div className="gap-5"></div>
                      {i.orderDishesList.map((j, ind2) => (
                        <div
                          className="font-2 align-corner confirm-order-item"
                          key={j._id}
                        >
                          <div>{`• ${j.dishName} x ${j.quantity}`}</div>
                          {j.orderState !== "Delivered" ? (
                            <button
                              className="blue-button bold double-line-button"
                              onClick={() => deliveredClick(i, j)}
                            >
                              Mark Delivered
                            </button>
                          ) : (
                            <div className="bold">Delivered</div>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div className="gap"></div>
            </div>
          </div>
        )}
      </Spinner>
    )
  );
}
