import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { getAjaxRequest } from "./common";
import Home from "./components/Home";
import Notification from "./components/Notification";
import { setProfile } from "./store/profileSlice";
import { useRegisterConfigs } from "./commonComponents/commonHook";
function App() {
  const [renderComponent, setRenderComponent] = useState(false);
  const dispatch = useDispatch();
  useRegisterConfigs();
  
  useEffect(() => {
    (async () => {
      try {
        let reqObj = {};
        if (sessionStorage.getItem("restaurantVisit")) {
          const visitObj = JSON.parse(
            sessionStorage.getItem("restaurantVisit")
          );
          if (new Date().getTime() - visitObj.visitTime < 5400000)
            reqObj = { visitRestaurantId: visitObj.restaurantId };
          sessionStorage.removeItem("restaurantVisit");
        }
        const profile = await getAjaxRequest("getProfile", reqObj);
        if (profile.data) dispatch(setProfile(profile.data));
        setRenderComponent(true);
      } catch (e) {
        console.log(e);
        setRenderComponent(true);
      }
    })();
  }, [dispatch]);

  return (
    <div>
      <BrowserRouter>
        {renderComponent ? <Home /> : null}
        <Notification />
      </BrowserRouter>
    </div>
  );
}

export default App;
