import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addNotification } from "../store/notificationSlice";
import { message } from "../config";
import { commonMethods, getAjaxRequest } from "../common";
import { useState } from "react";
import Spinner from "../commonComponents/Spinner";
import BillingComponent from "./BillingComponent";
import Popup from "./Popup";

export default function ArchivedOrders() {
  const dispatch = useDispatch();
  const [orderList, setOrderList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [restaurantDetails, setRestaurantDetails] = useState(null);

  const fetchArchiveOrders = useCallback(async () => {
    try {
      const orderData = await getAjaxRequest("getArchiveOrder", {}, setLoading);
      setOrderList(orderData.data.reverse()); // reversed list to show in descending order of time
    } catch (error) {
      console.log(error);
      dispatch(
        addNotification({
          type: "error",
          message: message.someErrorOccurred,
        })
      );
    }
  }, [dispatch]);

  const fetchRestaurantDetails = useCallback(async () => {
    try {
      const data = await getAjaxRequest("getRestaurantDetails", {});
      setRestaurantDetails(data.data);
    } catch (error) {
      console.log(error);
      dispatch(
        addNotification({
          type: "error",
          message: message.someErrorOccurred,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    fetchRestaurantDetails();
  }, [fetchRestaurantDetails]);

  const selectOrder = useCallback((order) => {
    setSelectedOrder(order);
    setPopupType("showBill");
  }, []);

  useEffect(() => {
    fetchArchiveOrders();
  }, [fetchArchiveOrders]);

  const getPopup = () => {
    switch (popupType) {
      case "showBill":
        return (
          <Popup
            closePopup={() => {
              setPopupType("");
              setSelectedOrder(null);
            }}
          >
            <div style={{ padding: "10px" }}>
              <h4>{`Bill ${selectedOrder.tableCategory} ${selectedOrder.tableNo}`}</h4>
              <div className="gap-5"></div>
              <BillingComponent
                tableBillOrderList={selectedOrder.orderDetails}
                billingItemList={selectedOrder.chargeDiscount}
                restaurantDetails={restaurantDetails}
                type="archiveOrder"
              />
            </div>
          </Popup>
        );

      default:
        break;
    }
  };

  return (
    <Spinner showSpinner={loading}>
      {getPopup()}
      <div className="full-width container-box">
        <div className="default-width">
          <h4>Archived Orders</h4>
          <div className="gap"></div>
          <div className="archivedOrdersContainer text-center">
            <div className="same-line font-0 font-bold full-width archiveOrderItem">
              <div style={{ width: "40%" }}>Date</div>
              <div style={{ width: "20%" }}>Table Category</div>
              <div style={{ width: "10%" }}>Table No.</div>
              <div style={{ width: "30%" }}>Amount</div>
            </div>
            {orderList.map((i) => (
              <div
                onClick={() => selectOrder(i)}
                key={i._id}
                className="same-line full-width archiveOrderItem font-2 clickable"
              >
                <div className="font-3" style={{ width: "40%" }}>
                  {commonMethods.getDateFormatted(i.orderDate, "dd MMM yyyy")}
                </div>
                <div style={{ width: "20%" }}>{i.tableCategory}</div>
                <div style={{ width: "10%" }}>{i.tableNo}</div>
                <div style={{ width: "30%" }}>{i.totalCost}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Spinner>
  );
}
