import React from "react";

export default function TermsConditions() {
  return (
    <div>
      <h4>Terms and Conditions</h4>
      <div className="gap-5"></div>
      <p>
        <strong>1. User Accounts:</strong>
      </p>
      <p>
        Users must create an account to access our Saas restaurant management
        system. Users are responsible for maintaining the confidentiality of
        their account credentials.
      </p>

      <p>
        <strong>2. Google Authentication:</strong>
      </p>
      <p>
        Users may log in through Google OAuth. By doing so, users grant us
        access to certain information from their Google accounts.
      </p>

      <p>
        <strong>3. Prohibited Activities:</strong>
      </p>
      <p>
        Users must not engage in any illegal or unauthorized activity on our
        platform.
      </p>

      <p>
        <strong>4. Service Availability:</strong>
      </p>
      <p>
        We strive to provide uninterrupted service, but we do not guarantee 100%
        availability.
      </p>

      <p>
        <strong>5. Termination:</strong>
      </p>
      <p>
        We reserve the right to terminate or suspend user accounts for
        violations of these terms.
      </p>

      <p>
        <strong>6. Limitation of Liability:</strong>
      </p>
      <p>
        We are not liable for any direct, indirect, incidental, or consequential
        damages.
      </p>

      <p>
        <strong>7. Governing Law:</strong>
      </p>
      <p>These terms are governed by the laws of [Your Jurisdiction].</p>

      <p>
        <strong>8. Changes to Terms and Conditions:</strong>
      </p>
      <p>
        We reserve the right to update our terms and conditions as needed. Any
        changes will be effective upon posting.
      </p>
    </div>
  );
}
