import React, { useCallback, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getAjaxRequest } from "../common";
import { useBeforeMount } from "../commonComponents/commonHook";
import Spinner from "../commonComponents/Spinner";
import { message } from "../config";
import { addNotification } from "../store/notificationSlice";
import { setRestaurantDetails } from "../store/restaurantBackendSlice";
import { removeNavigationList, setNavigationList } from "../store/sidebarSlice";
import ConfirmationPopup from "./ConfirmationPopup";
import DishesList from "./DishesList";
import NewDishForm from "./NewDishForm";
import Popup from "./Popup";
import TableWidget from "./TableWidget";
import { useNavigate } from "react-router-dom";
import config from "../config";
import FileUploadField from "../commonComponents/FileUploadField";

export default function BusinessAdminDashboard() {
  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useDispatch();
  const restaurantDetails = useSelector(
    (state) => state.restaurantBackend.restaurantDetails
  );
  const [dishCategorySelected, setDishCategorySelected] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [updateDishCategoryField, setUpdateDishCategoryField] = useState("");
  const [deleteCategoryField, setDeleteCategoryField] = useState("");
  const addNewDishCategoryForm = useForm({ mode: "onBlur" });
  const [dishSelected, setDishSelected] = useState(null);
  const componentName = useRef("businessAdminDashboard");
  const navigate = useNavigate();
  const [welcomeNoteField, setWelcomeNoteField] = useState("");
  const [mount] = useBeforeMount(() => {
    dispatch(
      setNavigationList({
        navList: [
          {
            scroll: true,
            url: "#table-categories",
            text: message.navListTableCategories,
            type: "sub",
          },
          {
            scroll: true,
            url: "#dish-categories",
            text: message.navListDishCategories,
            type: "sub",
          },
        ],
        componentName: componentName.current,
        topNav: message.navListAdminDashboard,
      })
    );
    return () => dispatch(removeNavigationList(componentName.current));
  });

  const fetchRestaurantDetails = useCallback(async () => {
    try {
      const response = await getAjaxRequest(
        "getRestaurantDetails",
        {},
        setShowSpinner
      );
      dispatch(setRestaurantDetails(response.data));
    } catch (error) {
      console.log(error);
      addNotification({
        type: "error",
        message: message.someErrorOccurred,
      });
    }
  }, [dispatch]);
  useEffect(() => {
    fetchRestaurantDetails();
  }, [fetchRestaurantDetails]);

  const updateDishCategory = useCallback((dishCategory) => {
    setUpdateDishCategoryField(dishCategory.categoryName);
    setPopupType("updateDishCategory");
  }, []);
  const reRenderWidget = useCallback(() => {
    setPopupType("");
    fetchRestaurantDetails();
  }, [fetchRestaurantDetails]);
  const confirmUpdateDishCategory = useCallback(async () => {
    if (!updateDishCategoryField) return;
    setPopupType("");
    try {
      await getAjaxRequest(
        "updateDishCategoryName",
        {
          newDishCategoryName: updateDishCategoryField,
          dishCategoryId: dishCategorySelected._id,
        },
        setShowSpinner
      );
      dispatch(
        addNotification({
          type: "success",
          message: "Dish Category Name Updated Successfully",
        })
      );
    } catch (error) {
      dispatch(
        addNotification({
          type: "error",
          message: message.someErrorOccurred,
        })
      );
    } finally {
      reRenderWidget();
    }
  }, [dishCategorySelected, reRenderWidget, dispatch, updateDishCategoryField]);
  const confirmDeleteDishCategory = useCallback(async () => {
    if (deleteCategoryField !== dishCategorySelected.categoryName) {
      dispatch(
        addNotification({
          type: "error",
          message: "Please Enter Correct Dish Category Name",
        })
      );
      return;
    }
    setPopupType("");
    try {
      await getAjaxRequest(
        "deleteDishCategory",
        {
          dishCategoryId: dishCategorySelected._id,
        },
        setShowSpinner
      );
      dispatch(
        addNotification({
          type: "success",
          message: "Dish Category Removed Successfully",
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        addNotification({
          type: "error",
          message: message.someErrorOccurred,
        })
      );
    } finally {
      reRenderWidget();
    }
  }, [deleteCategoryField, dishCategorySelected, dispatch, reRenderWidget]);
  const dishCategoryClick = useCallback(
    (action, dishCategory) => {
      setDishCategorySelected(dishCategory);
      switch (action) {
        case "create":
          setPopupType("createDish");
          break;
        case "update":
          updateDishCategory(dishCategory);
          break;
        case "delete":
          setDeleteCategoryField("");
          setPopupType("deleteDishCategory");
          break;
        default:
          console.log("error");
      }
    },
    [updateDishCategory]
  );
  const openUpdateDishform = useCallback((dish, dishCategory) => {
    setPopupType("updateDish");
    setDishSelected(dish);
    setDishCategorySelected(dishCategory);
  }, []);
  const dishClick = useCallback(
    (action, dish, dishCategory) => {
      switch (action) {
        case "update":
          openUpdateDishform(dish, dishCategory);
          break;
        default:
          console.log("error");
      }
    },
    [openUpdateDishform]
  );
  const createNewCategoryConfirm = useCallback(async () => {
    addNewDishCategoryForm.trigger();
    if (!addNewDishCategoryForm.formState.isValid) return;
    setPopupType("");
    try {
      await getAjaxRequest(
        "createNewDishCategory",
        addNewDishCategoryForm.getValues(),
        setShowSpinner
      );
      dispatch(
        addNotification({
          type: "success",
          message: "New Dish Category Created Successfully",
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        addNotification({
          type: "error",
          message: message.someErrorOccurred,
        })
      );
    } finally {
      reRenderWidget();
    }
  }, [addNewDishCategoryForm, dispatch, reRenderWidget]);
  const updateWelcomeNote = useCallback(async () => {
    try {
      await getAjaxRequest(
        "updateWelcomeNote",
        { welcomeNote: welcomeNoteField },
        setShowSpinner
      );
      dispatch(
        addNotification({
          type: "success",
          message: "Welcome Note Updated Successfully",
        })
      );
      fetchRestaurantDetails();
    } catch (error) {
      console.log(error);
      dispatch(
        addNotification({
          type: "error",
          message: message.someErrorOccurred,
        })
      );
    }
  }, [dispatch, welcomeNoteField, fetchRestaurantDetails]);
  const getPopup = () => {
    switch (popupType) {
      case "createDish":
        return (
          <Popup closePopup={() => setPopupType("")}>
            <NewDishForm
              reRender={reRenderWidget}
              dishCategory={dishCategorySelected}
            />
          </Popup>
        );
      case "updateDishCategory":
        return (
          <ConfirmationPopup
            confirmCall={confirmUpdateDishCategory}
            closePopup={() => setPopupType("")}
            customClose
          >
            <div className="container-box full-width">
              <div className="form-field min-content">
                <label>Update Dish Category Name:</label>
                <input
                  onChange={(e) => setUpdateDishCategoryField(e.target.value)}
                  value={updateDishCategoryField}
                  type="text"
                  className="input-field medium-input"
                />
                {!updateDishCategoryField && (
                  <div className="error-message">Category Name is Required</div>
                )}
              </div>
            </div>
          </ConfirmationPopup>
        );
      case "deleteDishCategory":
        return (
          <ConfirmationPopup
            confirmCall={confirmDeleteDishCategory}
            closePopup={() => setPopupType("")}
            customClose
          >
            <div className="align-center">
              <div className="text-center font-4">
                You can deactivate the dishes also, that way it won't appear on
                user's catelouge. Please enter the dish category name to delete
                it, and all it's associated dishes.
              </div>
              <div className="gap"></div>
              <input
                placeholder="Enter The Dish Category Name"
                onChange={(e) => setDeleteCategoryField(e.target.value)}
                type="text"
                className="input-field medium-input"
              />
            </div>
          </ConfirmationPopup>
        );
      case "addNewCategory":
        return (
          <ConfirmationPopup
            confirmCall={createNewCategoryConfirm}
            closePopup={() => setPopupType("")}
            customClose
          >
            <div className="align-center">
              <div className="form-field min-content">
                <label>New Dish Category Name:</label>
                <input
                  {...addNewDishCategoryForm.register("newCategoryName", {
                    required: true,
                  })}
                  type="text"
                  className="medium-input input-field"
                  placeholder="Enter New Dish Category Name"
                />
                {addNewDishCategoryForm.formState.errors.newCategoryName && (
                  <div className="error-message">
                    Please Enter New Dish Category Name
                  </div>
                )}
              </div>
            </div>
          </ConfirmationPopup>
        );
      case "updateDish":
        return (
          <Popup closePopup={() => setPopupType("")}>
            <NewDishForm
              reRender={reRenderWidget}
              dishCategory={dishCategorySelected}
              dishDetails={dishSelected}
            />
          </Popup>
        );
      case "changeWelcomeNote":
        return (
          <ConfirmationPopup
            confirmCall={updateWelcomeNote}
            closePopup={() => {
              setPopupType("");
              setWelcomeNoteField("");
            }}
          >
            <div className="container-box" style={{ height: "100px" }}>
              <div className="form-field min-content">
                <label>Welcome Note:</label>
                <input
                  onChange={(e) => setWelcomeNoteField(e.target.value)}
                  value={welcomeNoteField}
                  className="input-field medium-input"
                  placeholder="Enter New Welcome Note"
                  type="text"
                />
              </div>
            </div>
          </ConfirmationPopup>
        );
      default:
        console.log("error");
    }
  };
  const tableClick = useCallback(
    (tableObject) => {
      console.log(tableObject);
      navigate(
        `/restaurant/${restaurantDetails._id}/${tableObject.tableCategory._id}/${tableObject.tableNumber}`
      );
    },
    [restaurantDetails, navigate]
  );

  const saveCoverImage = useCallback(
    async (e) => {
      try {
        if (e.target.value.length) {
          await getAjaxRequest(
            "updateCoverImage",
            { fileName: e.target.value[0].fileName },
            setShowSpinner
          );
          dispatch(
            addNotification({
              type: "success",
              message: "Cover Image Updated Successfully",
            })
          );
          fetchRestaurantDetails();
        }
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch, fetchRestaurantDetails]
  );

  const coverImageChange = useCallback(
    (e) => {
      saveCoverImage(e);
    },
    [saveCoverImage]
  );

  const changeWelcomeNote = useCallback(() => {
    setPopupType("changeWelcomeNote");
  }, []);

  return (
    mount && (
      <>
        {popupType && getPopup()}
        <Spinner showSpinner={showSpinner} takeHeight>
          <div className="full-width container-box">
            <div className="default-width">
              <div id="table-categories" className="full-width">
                {restaurantDetails && (
                  <>
                    <TableWidget
                      tableDetails={restaurantDetails.tables}
                      reRender={reRenderWidget}
                      type={"businessAdminDashboard"}
                      tableClick={tableClick}
                    />
                    <div className="gap"></div>
                    <div>
                      <div
                        style={{
                          backgroundImage: `url(${config.baseResources}/${restaurantDetails.coverImage})`,
                        }}
                        className="full-width restaurant-landing-image"
                      >
                        <div
                          style={{ fontSize: "35px" }}
                          className="restaurant-heading"
                        >
                          <span style={{ paddingRight: "10px" }}>
                            <button
                              onClick={changeWelcomeNote}
                              className="pencilEditButton"
                            >
                              <div className="icon-background pencil-icon-button small-icon"></div>
                            </button>
                          </span>
                          <span>{restaurantDetails.welcomeNote}</span>
                        </div>
                      </div>
                      <div className="gap-5"></div>
                      <FileUploadField
                        name="coverImage"
                        label="Change Cover Image"
                        hideUploadedFiles
                        singleUpload
                        fileType={["png", "jpg", "jpeg"]}
                        changeEvent={coverImageChange}
                      />
                    </div>
                    <div className="gap"></div>
                    <div className="same-line">
                      <h4 id="dish-categories">All Dish Categories</h4>
                      <div className="gap-x-10"></div>
                      <button
                        onClick={() => {
                          addNewDishCategoryForm.reset();
                          setPopupType("addNewCategory");
                        }}
                        className="icon-background plus-icon-button"
                      ></button>
                    </div>
                    <div className="gap"></div>
                    <DishesList
                      type={"adminDashboard"}
                      allDishDetails={restaurantDetails}
                      dishCategoryClick={dishCategoryClick}
                      dishClick={dishClick}
                      reRender={reRenderWidget}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </Spinner>
      </>
    )
  );
}
