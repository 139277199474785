import { createSlice } from "@reduxjs/toolkit";
import { commonMethods } from "../common";

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: {
        navigationList: [],
        componentNavigationMap: {} // each component should have mapping saved which navs are registered under it
    },
    reducers: {
        setNavigationList(state, { payload }) {
            payload.navList.forEach(i => i.id = commonMethods.getRandomId());
            if (payload.componentName in state.componentNavigationMap) {
                state.componentNavigationMap[payload.componentName].forEach((i) => {
                    const index = state.navigationList.findIndex(j => j.text === i);
                    state.navigationList.splice(index, 1);
                });
            }
            const navPosition = payload.topNav ? state.navigationList.findIndex(i => i.text === payload.topNav) : -1;
            state.navigationList.splice(navPosition + 1, 0, ...payload.navList);
            state.componentNavigationMap[payload.componentName] = payload.navList.map(i => i.text);
        },
        removeNavigationList(state, { payload }) {
            state.componentNavigationMap[payload].forEach((i) => {
                const index = state.navigationList.findIndex(j => j.text === i);
                state.navigationList.splice(index, 1);
            });
            state.componentNavigationMap[payload] = [];
        }
    }
});

export default sidebarSlice.reducer;
export const { setNavigationList, removeNavigationList } = sidebarSlice.actions;