import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export default function Sidebar({ navigationClick }) {
  const sidebarNavigationList = useSelector(
    (state) => state.sidebar.navigationList
  );
  const profile = useSelector((state) => state.profile.profile);

  const [selectedNav, setSelectedNav] = useState(null);
  const navigate = useNavigate();
  const loc = useLocation();

  const scrollToElement = (el) => {
    const scrollHeight =
      document.querySelector(el).getBoundingClientRect().top +
      window.scrollY -
      60;
    window.scrollTo(0, scrollHeight);
  };

  const navClick = useCallback(
    (item) => {
      navigationClick && navigationClick();
      setSelectedNav(item);
      if (item.scroll) scrollToElement(item.url);
      else navigate(item.url);
    },
    [navigate, navigationClick]
  );

  useEffect(() => {
    const item = sidebarNavigationList.find((i) => i.url === loc.pathname);
    if (item) setSelectedNav(item);
  }, [loc, sidebarNavigationList]);

  const getSidebarNavigation = () => {
    return sidebarNavigationList.map((item) => {
      return (
        <button
          key={item.id}
          onClick={() => navClick(item)}
          className={`${
            item.type === "sub" ? "sidebar-sub-button" : "sidebar-button"
          } clickable ${item.id === selectedNav?.id ? "navClicked" : ""}`}
        >
          {item.text}
        </button>
      );
    });
  };
  return (
    <div>
      {/* <div style={{ height: "150px" }}></div> */}
      <div className="align-center full-width">
        {profile ? (
          <div className="userTitle">
            Hi, {profile.firstName}
          </div>
        ) : null}
        {getSidebarNavigation()}
      </div>
    </div>
  );
}
