import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export default function InlineRadio({ radioList, value, name, onChange }) {
  // if value is not provided radioList will be used as default configuration
  const [optionList, setOptionList] = useState([]);
  useEffect(() => {
    const temp = radioList.map(i => ({
      value: i.value,
      checked: i.value === value
    }));
    setOptionList(value ? temp : [...radioList]);
  }, [value, radioList]);
  return (
    <div className="radioTab">
      {optionList.map((i, ind) => (
        <div key={ind}>
          <input
            onClick={(e) => {
              onChange(e);
            }}
            type="radio"
            id={`${i.value}-${ind}`}
            value={i.value}
            name={name}
            defaultChecked={i.checked}
          />
          <label className="font-2" htmlFor={`${i.value}-${ind}`}>
            {i.value}
          </label>
        </div>
      ))}
    </div>
  );
}
