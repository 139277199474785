import React, { useEffect, useState } from "react";
import { commonMethods, getAjaxRequest } from "../common";
import Spinner from "../commonComponents/Spinner";

export default function VisitorInfo() {
  const [visitorData, setVisitorData] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    (async () => {
      const data = await getAjaxRequest("getVisitorData", {}, setShowSpinner);
      setVisitorData(data.data);
    })();
  }, []);

  return (
    <div className="full-width container-box">
      <div className="default-width">
        <h4>Vistors List</h4>
        <div className="gap"></div>
        <Spinner showSpinner={showSpinner} takeHeight>
          <div>
            <div className="archivedOrdersContainer text-center">
              <div className="same-line font-0 font-bold full-width visitorItem">
                <div style={{ width: "35%" }}>Name</div>
                <div style={{ width: "35%" }}>Email</div>
                <div style={{ width: "30%" }}>Visit Date</div>
              </div>
              {visitorData.map((i) => (
                <div
                  key={i._id}
                  className="same-line full-width visitorItem font-2 clickable"
                >
                  <div style={{ width: "35%", wordWrap: "break-word" }}>
                    {i.displayName}
                  </div>
                  <div
                    className="font-3"
                    style={{ width: "35%", wordWrap: "break-word" }}
                  >
                    {i.email}
                  </div>
                  <div style={{ width: "30%" }}>
                    {commonMethods.getDateFormatted(
                      i.visitTime,
                      "dd MMM yyyy hh:mm"
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Spinner>
      </div>
    </div>
  );
}
