import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearNotification } from "../store/notificationSlice";

export default function Notification() {
  const notificationList = useSelector((state) => {
    return state.notification.notificationList;
  });
  const dispatch = useDispatch();
  const [notificationTime, setNotificationTime] = useState(null);
  useEffect(
    (_) => {
      if (notificationList.length) {
        if (notificationTime) clearTimeout(notificationTime);
        setNotificationTime(
          setTimeout(() => {
            clearTimeout(notificationTime);
            setNotificationTime(null);
            dispatch(clearNotification());
          }, 4000)
        );
      }
    },
    [notificationList]
  );

  const getNotificationClass = function (type) {
    switch (type) {
      case "success":
        return "notification-success";
      case "error":
        return "notification-error";
      case "warning":
        return "notification-warning";
      default:
        return "notification-info";
    }
  };
  return (
    <div className="notification-wrapper">
      {notificationList.map((item, index) => {
        return (
          <div
            key={index}
            className={`notification-container ${getNotificationClass(
              item.type
            )}`}
          >
            {item.message}
          </div>
        );
      })}
    </div>
  );
}
