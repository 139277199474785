import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        notificationList: []
    },
    reducers: {
        addNotification(state, { payload }) {       // payload expected in form of {type, message}
            state.notificationList.push(payload);
        },
        clearNotification(state) {
            if (state.notificationList.length) state.notificationList = [];
        }
    }
});

export default notificationSlice.reducer;
export const { addNotification, clearNotification } = notificationSlice.actions;
