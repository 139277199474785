import { createSlice } from "@reduxjs/toolkit";

const generalSlice = createSlice({
    name: 'general',
    initialState: {
        screenWidth: window.innerWidth,
        isMobileScreen: true
    },
    reducers: {
        setScreenWidth(state, { payload }) {
            state.screenWidth = payload;
        },
        setIsMobileScreen(state, { payload }) {
            state.isMobileScreen = payload;
        }
    }
});

export const { setScreenWidth, setIsMobileScreen } = generalSlice.actions;
export default generalSlice.reducer;