import config, { message } from './config'
export const getAjaxRequest = function (urlKey, body, setShowSpinner, fileUpload) {
  const url = `${config.baseUrl}${apiEndpoint[urlKey]}`;
  return new Promise(async (resolve, reject) => {
    setShowSpinner && setShowSpinner(true);
    try {
      const response = await fetch(url, {
        method: body ? 'POST' : 'GET',
        ...(fileUpload ? {} : { headers: { 'Content-type': 'application/json' } }),
        body: fileUpload ? body : JSON.stringify(body),
        credentials: 'include'
      });
      const data = await response.json();
      setShowSpinner && setShowSpinner(false);
      if (response.ok) {
        resolve({
          status: response.status,
          data,
          headers: response.headers,
          url: response.url,
        });
      } else reject({ message: message.someErrorOccurred });
    } catch (error) {
      setShowSpinner && setShowSpinner(false);
      reject({ message: message.someErrorOccurred });
    }
  })
}

export const commonMethods = {
  getCurrency: function (currencyCode) {
    switch (currencyCode) {
      case 'USD':
        return '$';
      case 'INR':
        return '₹'
      default:
        return '$'
    }
  },
  filterNumber: function (val, decimalAllowed) {
    val = val.toString();
    if (!decimalAllowed) return val.replace(/\D/g, '');
    const filterVal = val.replace(/[^0-9.]/g, '');
    if (filterVal[filterVal.length - 1] === '.') return filterVal.slice(0, -1);
    return filterVal;
  },
  handleNumberOnlyField: function (event) {
    if (!/^\d+$/.test(event.key) && !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Home", "End"].includes(event.key)) {
      event.preventDefault();
    }
  },
  handleDecimalNumberOnlyField: function (event) {
    if (!/^\d+|\.$/.test(event.key) && !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Home", "End"].includes(event.key)) {
      event.preventDefault();
    }
    if (event.key === '.' && event.target.value.includes('.')) event.preventDefault();
  },
  getRandomId: function () {
    return Math.random().toString(36).substring(2, 7);
  },
  getDeepCopy: function (obj) {
    return JSON.parse(JSON.stringify(obj));
  },
  printHtml: function (htmlString, isMobile) {
    const printWindow = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

    printWindow.document.write(`<link rel="stylesheet" type="text/css" href="${config.hostUrl}/main.css"`);

    printWindow.document.write(`<body>${htmlString}</body>`);
    printWindow.document.close();
    printWindow.setTimeout(function () {
      printWindow.focus();
      printWindow.print();
      if (!isMobile) printWindow.close();
    }, 1000);
  },
  getDateFormatted: function (date, format) {
    date = date ? new Date(date) : new Date();
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    switch (format) {
      case "dd MMM yyyy": {
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
      }
      case "dd MMM yyyy hh:mm": {
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        hours = hours < 10 ? `0${hours}` : hours;
        minutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${day} ${month} ${year} ${hours}:${minutes}`;
      }
      default:
        break;
    }
  }
}

const apiEndpoint = {
  getDishes: '/restaurant/getDishes',
  uploadFile: '/uploadFile',
  acquireUserRegister: '/admin/acquireUserRegister',
  getProfile: '/utility/getProfile',
  logout: '/utility/login/logout',
  getRestaurantDetails: '/restaurantInternal/worker/getRestaurantDetails',
  addNewTableCategory: '/restaurantInternal/admin/addNewTableCategory',
  deleteTableCategory: '/restaurantInternal/admin/deleteTableCategory',
  updateTableCategory: '/restaurantInternal/admin/updateTableCategory',
  addNewDish: '/restaurantInternal/admin/addNewDish',
  updateDishCategoryName: '/restaurantInternal/admin/updateDishCategoryName',
  deleteDishCategory: '/restaurantInternal/admin/deleteDishCategory',
  createNewDishCategory: '/restaurantInternal/admin/createNewDishCategory',
  updateDish: '/restaurantInternal/admin/updateDish',
  removeDish: '/restaurantInternal/admin/removeDish',
  toggleDishStatus: '/restaurantInternal/admin/toggleDishStatus',
  placeOrder: '/restaurant/placeOrder',
  addChargeOrDiscount: '/restaurantInternal/admin/addChargeOrDiscount',
  updateChargeOrDiscount: '/restaurantInternal/admin/updateChargeOrDiscount',
  deleteChargeDiscount: '/restaurantInternal/admin/deleteChargeDiscount',
  getArchiveOrder: '/restaurantInternal/admin/getArchiveOrder',
  updateCoverImage: '/restaurantInternal/admin/updateCoverImage',
  updateWelcomeNote: '/restaurantInternal/admin/updateWelcomeNote',
  getVisitorData: '/restaurantInternal/admin/getVisitorData',
  setOrUpdateRestaurant: '/admin/setOrUpdateRestaurant',
  searchUser: '/restaurantInternal/admin/searchUser',
  sendEmailBill: '/restaurantInternal/admin/sendEmailBill'
}