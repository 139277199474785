import React from "react";

export default function ContactUs() {
  return (
    <div
      className="full-width align-center container-box"
      style={{ height: "60vh" }}
    >
      <div className="text-center font-0" style={{ fontSize: "28px" }}>
        We Will Be Happy To Answer Your Queries!
      </div>
      <div className="gap"></div>
      <div className="gap"></div>
      <div className="align-center">
        <h4>Biztro</h4>
        <a href="mailto:contact@biztro.app">contact@biztro.app</a>
        <a href="tel:+918777805112">+918777805112</a>
      </div>
      <div className="gap"></div>
      <div className="container-box" style={{ height: "100px" }}>
        <img src="/files/misc/contact-qr.jpg" alt="contact us link qr" />
      </div>
    </div>
  );
}
