import React from "react";
import { useForm } from "react-hook-form";
import InlineRadio from "../commonComponents/InlineRadio";
import ToggleCheckbox from "./ToggleCheckbox";
import { useCallback } from "react";
import { getAjaxRequest } from "../common";
import { useDispatch } from "react-redux";
import { addNotification } from "../store/notificationSlice";
import { message } from "../config";
import Spinner from "../commonComponents/Spinner";
import { useState } from "react";
import { useEffect } from "react";
import ConfirmationPopup from "./ConfirmationPopup";

export default function ChargesOrDiscountForm({ formData, closePopup }) {
  const {
    register,
    getValues,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm({ mode: "onBlur" });
  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useDispatch();
  const [removeItemConfirm, setRemoveItemConfirm] = useState(false);

  register("active", { value: false });
  register("type", { value: "Discount" });
  register("amountType", { value: "Fixed" });
  const submitForm = useCallback(async () => {
    trigger();
    if (!isValid) return;
    try {
      const submitUrl = formData
        ? "updateChargeOrDiscount"
        : "addChargeOrDiscount";
      await getAjaxRequest(submitUrl, getValues(), setShowSpinner);
      dispatch(
        addNotification({
          type: "success",
          message: "Discount/Charge added successfully",
        })
      );
      closePopup();
    } catch (error) {
      console.log(error);
      dispatch(
        addNotification({
          type: "error",
          message: message.someErrorOccurred,
        })
      );
    }
  }, [getValues, isValid, trigger, dispatch, closePopup, formData]);

  useEffect(() => {
    if (formData) {
      setValue("name", formData.name);
      setValue("type", formData.type);
      setValue("active", formData.active);
      setValue("amountType", formData.amountType);
      setValue("amount", formData.amount);
      setValue("note", formData.note);
      setValue("_id", formData._id);
    }
  }, [formData, setValue]);

  const removeItem = useCallback(async () => {
    try {
      await getAjaxRequest("deleteChargeDiscount", { id: formData._id }, setShowSpinner);
      dispatch(
        addNotification({
          type: "success",
          message: "Removed Successfully",
        })
      );
      closePopup();
    } catch (error) {
      console.log(error);
      dispatch(
        addNotification({
          type: "error",
          message: message.someErrorOccurred,
        })
      );
    }
  }, [closePopup, formData, dispatch]);

  return (
    <Spinner showSpinner={showSpinner}>
      {removeItemConfirm && (
        <ConfirmationPopup
          confirmCall={removeItem}
          closePopup={() => setRemoveItemConfirm(false)}
        >
          <div className="container-box text-center font-4">
            Are you sure? Remove this billing item?
          </div>
        </ConfirmationPopup>
      )}
      <div>
        <h4>Add Charge and Discount</h4>
        <div className="gap-5"></div>
        <div>
          <div className="form-field">
            <label>Charge/Discount Name:</label>
            <input
              type="text"
              className="medium-input input-field"
              placeholder="Charge/Discount Name"
              {...register("name", { required: true })}
            />
            {errors.name && (
              <div className="error-message">
                Please Enter Charge/Discount Name
              </div>
            )}
          </div>
          <div className="form-field">
            <div>
              <label>Type:</label>
            </div>
            <div>
              <InlineRadio
                radioList={[
                  { value: "Discount", checked: true },
                  { value: "Charge" },
                ]}
                value={getValues().type}
                name="chargeType"
                onChange={(e) => setValue("type", e.target.value)}
              />
            </div>
          </div>
          <div className="form-field">
            <div>
              <label>Active:</label>
            </div>
            <ToggleCheckbox
              value={getValues().active}
              onChange={() => {
                setValue("active", !getValues().active);
                trigger("active");
              }}
            />
          </div>
          <div className="form-field">
            <InlineRadio
              radioList={[
                { value: "Fixed", checked: true },
                { value: "Percentage" },
              ]}
              name="amountType"
              value={getValues().amountType}
              onChange={(e) => {
                setValue("amountType", e.target.value);
              }}
            />
          </div>
          <div className="form-field">
            <label>Amount/Percentage:</label>
            <input
              type="number"
              className="medium-input input-field"
              placeholder="Amount/Percentage"
              {...register("amount", { required: true })}
            />
            {errors.amount && (
              <div className="error-message">
                Please Enter Amount/Percentage
              </div>
            )}
          </div>
          <div className="form-field">
            <label>Personal Note:</label>
            <textarea
              style={{ minHeight: "120px" }}
              className="font-2 input-field medium-input"
              placeholder="Personal Note"
              {...register("note", { value: "" })}
            ></textarea>
          </div>
          <div className="align-right">
            {!!formData && (
              <>
                <button
                  onClick={() => {
                    setRemoveItemConfirm(true);
                  }}
                  className="double-line-button red-button font-3"
                >
                  Remove
                </button>
                <div className="gap-x-5"></div>
              </>
            )}
            <button
              onClick={submitForm}
              className="double-line-button green-button font-3"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </Spinner>
  );
}
