import React, { useCallback, useState } from "react";
import DebounceInput from "./DebounceInput";

export default function SearchInputField(props) {
  const [showSuggestion, setShowSuggestion] = useState(false);
  const selectSuggestion = useCallback(
    (value) => {
      setShowSuggestion(false);
      props.onSelection(value);
    },
    [props]
  );
  return (
    <div
      tabIndex="0"
      onBlur={() => setShowSuggestion(false)}
      onFocus={() => setShowSuggestion(true)}
    >
      {props.inputType === "debounce" ? (
        <DebounceInput {...props.inputProps} />
      ) : (
        <input {...props.inputProps} />
      )}
      {showSuggestion && !!props.suggestionList.length && (
        <div style={{ height: "0px" }}>
          <div className="suggestion-container">
            {props.suggestionList.map((i) => {
              return (
                <div
                  onMouseDown={() => selectSuggestion(i)}
                  key={i._id}
                  className="suggestion-item font-3 clickable"
                >
                  {i.label}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
