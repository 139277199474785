import React from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { useBeforeMount } from "../commonComponents/commonHook";
import { message } from "../config";
import { removeNavigationList, setNavigationList } from "../store/sidebarSlice";
import OrderButton from "./OrderButton";
import OrderStatus from "./OrderStatus";
import RestaurantLanding from "./RestaurantLanding";
export default function RestaurantCustomerPortal() {
  const dispatch = useDispatch();
  const componentName = useRef("customerPortalHome");
  const [mount] = useBeforeMount(() => {
    dispatch(
      setNavigationList({
        navList: [
          {
            scroll: false,
            url: "#",
            text: message.navListDishCategories,
            type: "main",
          },
        ],
        componentName,
      })
    );
    return () => dispatch(removeNavigationList(componentName));
  }, [componentName]);
  return (
    mount && <div>
      <RestaurantLanding />
      <OrderButton />
      <OrderStatus />
    </div>
  );
}
