import { useEffect, useState } from "react";

export default function Popup({ children, closePopup }) {
  const [popupVisible, setPopupVisible] = useState(false);
  useEffect(() => {
    setPopupVisible(true);
  }, [children]);
  const close = () => {
    setPopupVisible(false);
    if (closePopup) closePopup();
  };
  return popupVisible ? (
    <>
      <div className="popup-background container-box" onClick={close}>
        <div className="popup-container" onClick={e => e.stopPropagation()}>{children}</div>
      </div>
    </>
  ) : null;
}
