import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export default function ConfirmationPopup({
  children,
  closePopup,
  confirmCall,
  cancelCall,
  customClose
}) {
  const [popupVisible, setPopupVisible] = useState(false);
  useEffect(() => {
    setPopupVisible(true);
  }, [children]);
  const close = () => {
    setPopupVisible(false);
    if (closePopup) closePopup();
  };
  return popupVisible ? (
    <div className="popup-background container-box" onClick={close}>
      <div
        className="popup-container confirmation-popup align-corner-vertical"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="container-box">{children}</div>
        <div className="align-corner confirmation-button">
          <button
            onClick={() => {
              if (cancelCall) cancelCall();
              close();
            }}
            className="double-line-button red-button font-3"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              if (confirmCall) confirmCall();
              if (!customClose) close();
            }}
            className="double-line-button green-button font-3"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  ) : null;
}
