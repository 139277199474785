import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

export default function CollapsibleWidget({ children, open }) {
  const collapseContainer = useRef();
  const [widgetHeight, setWidgetHeight] = useState(0);
  useEffect(() => {
    setWidgetHeight(collapseContainer.current.clientHeight);
  }, [children]);
  return (
    <div
      className="full-width collapse-widget-container"
      style={{
        maxHeight: open ? `${widgetHeight}px` : "0px",
      }}
    >
      <div ref={collapseContainer} className="full-width">
        {children}
      </div>
    </div>
  );
}
