import React, { useCallback, useState } from "react";
import Sidebar from "./Sidebar";

export default function MobileBurgerButton() {
  const location = "-75%";
  const [sideBarLocation, setSideBarLocation] = useState(location);
  const toggleSidebar = useCallback(() => {
    setSideBarLocation(sideBarLocation === location ? "0%" : location);
  }, [sideBarLocation]);

  return (
    <div>
      <div className="hamburger-button" onClick={toggleSidebar}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div style={{ left: sideBarLocation }} className="sidebar-content container-box">
        <Sidebar navigationClick={toggleSidebar} />
      </div>
      {sideBarLocation !== location ? (
        <div className="sidebar-background" onClick={toggleSidebar}></div>
      ) : null}
    </div>
  );
}
