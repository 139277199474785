import React, { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import loadingAnimated from "../assets/loading-animated.gif";
import { customerPortalSocket } from "../socket";
import config from "../config";
import Popup from "./Popup";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addNotification } from "../store/notificationSlice";

export default function OrderStatus() {
  const [openDetails, setOpenDetails] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [showBill, setShowBill] = useState(false);
  const dispatch = useDispatch();
  const { tableCategoryId, tableNumber, restaurantId } = useParams();

  const toggleOrderDetails = function () {
    setOpenDetails(openDetails ? false : true);
  };
  useEffect(() => {
    const listener = (data) => {
      console.log(data);
      setOrderList(data);
    };
    customerPortalSocket.on("orderUpdate", listener);
    return () => customerPortalSocket.off("orderUpdate", listener);
  }, []);

  const orderStatusMessage = useMemo(() => {
    const orderedOrConfirmedPresent = orderList.find((i) =>
      ["Ordered", "Confirmed"].includes(i.orderState)
    );
    if (orderedOrConfirmedPresent) return "Your Order is being prepared";
    else return "Enjoy your meal ..";
  }, [orderList]);

  const getOrderItem = (item) => {
    return (
      <>
        <div className="orders">
          <h5>
            {["Ordered", "Confirmed"].includes(item.orderState)
              ? "Preparing your order"
              : item.orderState === "Delivered"
              ? "Order is delivered"
              : ""}
          </h5>
          <div className="gap-5"></div>
          <div>
            {item.orderDishesList.map((i, index) => {
              return (
                <div key={i._id} className="align-corner font-2">
                  <div className="each-order">
                    {`• ${i.dishName} x ${i.quantity}`}
                  </div>
                  <div className="same-line">
                    <div
                      className={`bold ${
                        ["Ordered", "Confirmed"].includes(i.orderState)
                          ? "blueText"
                          : i.orderState === "Delivered"
                          ? "greenText"
                          : ""
                      }`}
                    >
                      {["Ordered", "Confirmed"].includes(i.orderState)
                        ? "Processing"
                        : i.orderState === "Delivered"
                        ? "Delivered"
                        : ""}
                    </div>
                    <div
                      className="cover-image"
                      style={{
                        backgroundImage: ["Ordered", "Confirmed"].includes(
                          i.orderState
                        )
                          ? `url(${config.baseResources}/misc/cooking.gif)`
                          : i.orderState === "Delivered"
                          ? `url(${config.baseResources}/misc/served.png)`
                          : "",
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                    ></div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="gap"></div>
      </>
    );
  };

  const viewBillClick = useCallback(() => {
    setShowBill(true);
  }, []);

  const totalBillCost = useMemo(() => {
    return orderList.reduce((acc, cur) => acc + Number(cur.totalCost), 0);
  }, [orderList]);

  const callPerson = useCallback(() => {
    customerPortalSocket.emit(
      "callPerson",
      { restaurantId, tableCategoryId, tableNo: tableNumber },
      () => {
        dispatch(
          addNotification({
            type: "success",
            message: "Someone will attend your table. Please wait ..",
          })
        );
      }
    );
  }, [dispatch, restaurantId, tableCategoryId, tableNumber]);

  return (
    <>
      {showBill && (
        <Popup closePopup={() => setShowBill(false)}>
          <div style={{ padding: "10px" }}>
            <h4>Order Billing</h4>
            <div className="gap-5"></div>
            <div className="billContainer">
              {orderList.map((i) => {
                return i.orderDishesList.map((j) => (
                  <div
                    key={`${j._id}`}
                    className="billOrderItem font-2 align-corner"
                  >
                    <div>{`${j.dishName} x ${j.quantity}`}</div>
                    <div>{j.totalCost}</div>
                  </div>
                ));
              })}
              <div className="dotted-line"></div>
              <div className="billOrderItem font-2 align-corner">
                <div>Total</div>
                <div>{totalBillCost}</div>
              </div>
            </div>
            <div className="gap"></div>
            <div className="gap"></div>
            <div className="container-box">
              <button
                onClick={callPerson}
                className="order-button button-small font-2"
              >
                Call a Person
              </button>
            </div>
            <div className="gap"></div>
          </div>
        </Popup>
      )}
      {orderList.length ? (
        <div className="order-status-container">
          <div
            onClick={toggleOrderDetails}
            className="order-details-ribbon align-corner clickable"
          >
            <div className="vertical-center">
              <div className="container-box" style={{ width: "30px" }}>
                <img src={loadingAnimated} alt="" />
              </div>
              <div className="gap-x-10"></div>
              <div className="font-4">{orderStatusMessage}</div>
            </div>
            {openDetails ? (
              <div className="icon double-arrow-down"></div>
            ) : (
              <div className="icon double-arrow-up"></div>
            )}
          </div>
          <div
            className="order-details"
            style={{ maxHeight: openDetails ? "500px" : "0px" }}
          >
            <div className="gap-5"></div>
            <div className="vertical-center">
              <button onClick={viewBillClick} className="order-button font-2">
                View Bill
              </button>
            </div>
            <div className="gap-10"></div>

            <div className="gap"></div>
            {orderList.map((i, index) => (
              <div key={i._id}>{getOrderItem(i)}</div>
            ))}
            <div className="gap"></div>
          </div>
        </div>
      ) : null}
    </>
  );
}
