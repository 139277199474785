import { createSlice } from "@reduxjs/toolkit";

const orderButtonSlice = createSlice({
    name: 'orderButton',
    initialState: {
        orderList: []
    },
    reducers: {
        addOrder(state, { payload }) {
            const existingOrder = state.orderList.find(item => item._id === payload._id);
            if (existingOrder) existingOrder.count = payload.count;
            else state.orderList.push(payload);
        },
        removeOrder(state, {payload}) {
            const ind = state.orderList.findIndex(i => i._id === payload._id);
            state.orderList.splice(ind, 1);
        },
        resetOrderList(state) {
            state.orderList = [];
        }
    }
});

export default orderButtonSlice.reducer;

export const { addOrder, removeOrder, resetOrderList } = orderButtonSlice.actions;