import { createSlice } from "@reduxjs/toolkit";

const restaurantLandingSlice = createSlice({
    name: 'restaurantLanding',
    initialState: {
        dishDetails: null
    },
    reducers: {
        setDishDetails(state, { payload }) {
            state.dishDetails = payload;
        }
    }
});

export default restaurantLandingSlice.reducer;
export const { setDishDetails } = restaurantLandingSlice.actions;
