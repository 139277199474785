import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDishDetails } from "../store/restaurantLanding";
import { useParams } from "react-router-dom";
import config, { message } from "../config";
import DishesList from "./DishesList";
import { customerPortalSocket } from "../socket";
import { getAjaxRequest } from "../common";
import Spinner from "../commonComponents/Spinner";
import { addNotification } from "../store/notificationSlice";

export default function RestaurantLanding() {
  const { restaurantId, tableCategoryId, tableNumber } = useParams();
  const dishDetails = useSelector(
    (state) => state.restaurantLanding.dishDetails
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const uniqueId = restaurantId.split("-").pop();
    (async () => {
      try {
        const dishDetails = await getAjaxRequest(
          "getDishes",
          {
            restaurantId: uniqueId,
          },
          setLoading
        );
        dispatch(setDishDetails(dishDetails.data));
      } catch (error) {
        dispatch(
          addNotification({
            type: "error",
            message: message.someErrorOccurred,
          })
        );
        console.log(error);
      }
    })();
    customerPortalSocket.connect();
    customerPortalSocket.emit("joinRoom", {
      restaurantId,
      tableCategoryId,
      tableNumber,
    });
    return () => customerPortalSocket.disconnect();
  }, [dispatch, restaurantId, tableCategoryId, tableNumber]);

  const profile = useSelector(state => state.profile.profile);

  useEffect(() => {
    if (!profile) sessionStorage.setItem('restaurantVisit', JSON.stringify({
      restaurantId,
      visitTime: new Date().getTime()
    }));
  })

  return (
    <>
      <Spinner showSpinner={loading} takeHeight>
        <div className="full-width">
          <div className="full-width">
            <div className="full-width-mobile">
              <div
                style={{
                  backgroundImage: `url(${config.baseResources}/${dishDetails?.coverImage})`,
                }}
                className="full-width restaurant-landing-image"
              >
                <div className="restaurant-heading">
                  {dishDetails?.welcomeNote}
                </div>
              </div>
              <div className="gap"></div>
              <DishesList allDishDetails={dishDetails} />
            </div>
          </div>
        </div>
      </Spinner>
    </>
  );
}
