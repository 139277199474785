import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dishIcon from "../assets/dish-icon.png";
import { commonMethods } from "../common";
import penguinButler from "../assets/penguin-butler.png";
import { removeOrder, resetOrderList } from "../store/orderButtonSlice";
import { addNotification } from "../store/notificationSlice";
import { useParams } from "react-router-dom";
import { customerPortalSocket } from "../socket";
import { message } from "../config";

export default function OrderButton() {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [detailsReverseAnimation, setDetailsReverseAnimation] = useState(false);
  const orderList = useSelector((state) => state.orderButton.orderList);
  const dispatch = useDispatch();
  const { tableCategoryId, tableNumber, restaurantId } = useParams();

  const closeDetails = function () {
    setDetailsReverseAnimation(true);
    setTimeout(() => {
      setDetailsOpen(false);
      setDetailsReverseAnimation(false);
    }, 450);
  };
  const removeItem = function (dish) {
    dispatch(removeOrder(dish));
  };
  const calculateTotal = function () {
    const amount = orderList.reduce(
      (acc, item) => acc + Number(item.discountedPrice) * item.count,
      0
    );
    return `${commonMethods.getCurrency(orderList[0].currency)}${amount}`;
  };

  const placeOrder = () => {
    const orderDishList = orderList.map((i) => ({
      dishId: i._id,
      dishCategoryId: i.dishCategoryId,
      quantity: i.count,
    }));
    customerPortalSocket.volatile.emit(
      "placeOrder",
      {
        restaurantId: restaurantId.split("-").pop(),
        tableCategoryId,
        tableNumber,
        orderDishList,
      },
      (msg) => {
        if (msg.success) {
          dispatch(
            addNotification({
              type: "success",
              message: "Your order is placed. On it's way..",
            })
          );
          closeDetails();
          dispatch(resetOrderList());
        } else
          dispatch(
            addNotification({
              type: "error",
              message: message.someErrorOccurred,
            })
          );
      }
    );
  };

  const callPerson = useCallback(() => {
    customerPortalSocket.emit('callPerson', {restaurantId, tableCategoryId, tableNo: tableNumber}, () => {
      dispatch(addNotification({
        type: 'success',
        message: 'Someone will attend your table. Please wait ..'
      }))
    });

  }, [dispatch, restaurantId, tableCategoryId, tableNumber]);

  const detailsBody = (
    <div
      onClick={() => closeDetails()}
      className="popup-background container-box"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`order-list-container ${
          detailsReverseAnimation ? "order-list-close" : ""
        }`}
      >
        {orderList.length ? (
          <div>
            <div className="order-list-items">
              {orderList.map((i, ind) => (
                <div key={i._id} className="order-item">
                  {i.name} x {i.count} {commonMethods.getCurrency(i.currency)}
                  {i.discountedPrice}{" "}
                  <span>
                    <button
                      onClick={() => removeItem(i)}
                      className="font-4 order-remove-button"
                    >
                      Remove
                    </button>
                  </span>
                </div>
              ))}
            </div>
            <div className="dotted-line"></div>
            <div className="gap-5"></div>
            <div className="order-item text-right">
              Total {calculateTotal()}
            </div>
          </div>
        ) : (
          <div className="container-box order-list-placeholder">
            <img src={penguinButler} alt="" />
            <h3 className="order-list-placeholder-text">Make an Order!</h3>
          </div>
        )}
        <div className="gap"></div>
        <div className="container-box">
          {orderList.length ? (
            <button
              onClick={placeOrder}
              className="order-button button-small font-2"
            >
              Place Order
            </button>
          ) : null}
          <button onClick={callPerson} className="order-button button-small font-2">
            Call a Person
          </button>
        </div>
        <div className="gap-5"></div>
      </div>
    </div>
  );

  return (
    <>
      <button
        onClick={() => setDetailsOpen(true)}
        className="container-box order-circle-button"
      >
        <img src={dishIcon} alt="" />
        {orderList.length ? (
          <div className="button-badge">{orderList.length}</div>
        ) : null}
      </button>
      {detailsOpen && detailsBody}
    </>
  );
}
