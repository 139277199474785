import React from "react";
import Header from "./Header";
import Login from "./Login";
import { Routes, Route, Navigate } from "react-router-dom";
import RegisterBusiness from "./RegisterBusiness";
import RestaurantCustomerFace from "./RestaurantCustomerPortal";
import DefaultPage from "./DefaultPage";
import Logout from "./Logout";
import BusinessAdminDashboard from "./BusinessAdminDashboard";
import ActionCenter from "./ActionCenter";
import { useBeforeMount } from "../commonComponents/commonHook";
import { useDispatch, useSelector } from "react-redux";
import { message } from "../config";
import { removeNavigationList, setNavigationList } from "../store/sidebarSlice";
import BillingCenter from "./BillingCenter";
import ArchivedOrders from "./ArchivedOrders";
import DesktopSidebar from "./DesktopSidebar";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsConditions from "./TermsConditions";
import ContactUs from "./ContactUs";
import VisitorInfo from "./VisitorInfo";
import RegisterRestaurant from "./RegisterRestaurant";

export default function Home() {
  const profile = useSelector((state) => state.profile.profile);
  const dispatch = useDispatch();
  const [mount] = useBeforeMount(() => {
    const restaurantAdminNavList = [
      {
        scroll: false,
        type: "main",
        url: "/",
        text: message.navListHome,
      },
      ...(profile
        ? [
            ...(profile.access.worker
              ? [
                  {
                    scroll: false,
                    type: "main",
                    url: "/actionCenter",
                    text: message.navListActionCenter,
                  },
                ]
              : []),
            ...(profile.access.admin
              ? [
                  {
                    scroll: false,
                    type: "main",
                    url: "/businessAdminDashboard",
                    text: message.navListAdminDashboard,
                  },
                  {
                    scroll: false,
                    type: "main",
                    url: "/billingCenter",
                    text: message.billingCenter,
                  },
                  {
                    scroll: false,
                    type: "main",
                    url: "/archiveOrder",
                    text: message.archiveOrders,
                  },
                  {
                    scroll: false,
                    type: "main",
                    url: "/visitor-info",
                    text: message.visitorInfo,
                  },
                ]
              : []),
            {
              scroll: false,
              type: "main",
              url: "/logout",
              text: message.navListLogout,
            },
          ]
        : [
            {
              scroll: false,
              type: "main",
              url: "/login",
              text: message.navListLogin,
            },
          ]),
      {
        scroll: false,
        type: "main",
        url: "/privacy-policy",
        text: message.privacyPolicy,
      },
      {
        scroll: false,
        type: "main",
        url: "/terms-and-conditions",
        text: message.termsAndConditions,
      },
      {
        scroll: false,
        type: "main",
        url: "/contact-us",
        text: message.contactUs,
      },
      ...(profile?.adminAccess?.access
        ? [
            {
              scroll: false,
              type: "main",
              url: "/register-restaurant",
              text: message.registerRestaurant,
            },
          ]
        : []),
    ];
    dispatch(
      setNavigationList({
        navList: restaurantAdminNavList,
        componentName: "Home",
      })
    );
    return () => removeNavigationList("Home");
  });
  return (
    mount && (
      <>
        <div className="horizontal-center">
          <DesktopSidebar />
          <div className="mainBodyContainer">
            <Header></Header>
            <div style={{ height: "60px" }}></div>
            <div>
              <Routes>
                <Route path="/" element={<DefaultPage />}></Route>
                <Route
                  path={
                    "/restaurant/:restaurantId/:tableCategoryId/:tableNumber"
                  }
                  element={<RestaurantCustomerFace />}
                ></Route>
                <Route path={"/login"} element={<Login />}></Route>
                <Route
                  path="/registerBusiness"
                  element={<RegisterBusiness />}
                ></Route>
                <Route
                  path="/businessAdminDashboard"
                  element={<BusinessAdminDashboard />}
                ></Route>
                <Route path="/actionCenter" element={<ActionCenter />}></Route>
                <Route
                  path="/billingCenter"
                  element={<BillingCenter />}
                ></Route>
                <Route
                  path="/archiveOrder"
                  element={<ArchivedOrders />}
                ></Route>
                <Route path="/logout" element={<Logout />}></Route>
                <Route
                  path="/privacy-policy"
                  element={<PrivacyPolicy />}
                ></Route>
                <Route
                  path="/terms-and-conditions"
                  element={<TermsConditions />}
                ></Route>
                <Route path="/contact-us" element={<ContactUs />}></Route>
                <Route path="/visitor-info" element={<VisitorInfo />}></Route>
                <Route
                  path="register-restaurant"
                  element={<RegisterRestaurant />}
                ></Route>
                <Route path="*" element={<Navigate to="/" />}></Route>
              </Routes>
            </div>
          </div>
        </div>
      </>
    )
  );
}
