import { createSlice } from "@reduxjs/toolkit";

const billingCenterSlice = createSlice({
    name: 'billingCenter',
    initialState: {
        restaurantDetails: null,
        billingOrderList: []
    },
    reducers: {
        setRestaurantDetails(state, { payload }) {
            state.restaurantDetails = payload;
        },
        setBillingOrderList(state, { payload }) {
            state.billingOrderList = payload;
        }
    }
});

export default billingCenterSlice.reducer;
export const { setRestaurantDetails, setBillingOrderList } = billingCenterSlice.actions;