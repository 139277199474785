const prodProperties = {
    baseUrl: 'https://biztro.app/api',
    socketBaseUrl: 'https://biztro.app',
    hostUrl: 'https://biztro.app',
    baseResources: 'https://biztro.app/resources',
    socketConfiguration: {
        autoConnect: false
    }
};

const devProperties = {
    baseUrl: window.location.hostname === 'localhost' ? 'http://localhost:5000/api' : 'http://192.168.0.101:5000/api',
    socketBaseUrl: window.location.hostname === 'localhost' ? 'http://localhost:5000' : 'http://192.168.0.101:5000', 
    hostUrl: window.location.hostname === 'localhost' ? 'http://localhost:3000' : 'http://192.168.0.101:3000',
    baseResources: window.location.hostname === 'localhost' ? 'http://localhost:5000/resources' : 'http://192.168.0.101:5000/resources',
    socketConfiguration: {
        autoConnect: false,
        withCredentials: true
    }
}

export default window.location.hostname.split('.').slice(-2).join('.') === 'biztro.app' ? prodProperties : devProperties;

const message = {
    someErrorOccurred: "Some Error Occurred",
    navListHome: "Home",
    navListAdminDashboard: "Admin Dashboard",
    navListActionCenter: "Action Center",
    navListLogin: "Login",
    navListLogout: "Logout",
    navListDishCategories: 'Dish Categories',
    navListTableCategories: "Table Categories",
    navListLiveOrders: "Live Orders",
    navListConfirmedOrders: "Confirmed Orders",
    attendTable: "Attend Table",
    billingCenter: "Billing Center",
    archiveOrders: "Archive Orders",
    privacyPolicy: "Privacy Policy",
    termsAndConditions: "Terms & Conditions",
    contactUs: "Contact Us",
    visitorInfo: "Visitors Info",
    registerRestaurant: "Register Restaurant"

};

export { message };