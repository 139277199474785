import React, { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAjaxRequest } from "../common";
import config, { message } from "../config";
import { addNotification } from "../store/notificationSlice";
import ConfirmationPopup from "./ConfirmationPopup";

export default function Logout() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.profile);
  const navigate = useNavigate();
  useEffect(() => {
    if (!profile) navigate("/");
  }, []);
  const logoutConfirm = useCallback(async () => {
    try {
      await getAjaxRequest("logout", {});
      window.location.href = config.hostUrl;
    } catch (error) {
      console.log(error);
      dispatch(
        addNotification({
          type: "error",
          message: message.someErrorOccurred,
        })
      );
    }
  }, [dispatch]);
  return (
    <div>
      <ConfirmationPopup
        confirmCall={logoutConfirm}
        closePopup={() => navigate("/")}
      >
        <div className="font-3">Are you sure to logout?</div>
      </ConfirmationPopup>
    </div>
  );
}
