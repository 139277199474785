import React from "react";

export default function Counter({count, setCount}) {
  return (
    <div className="container-box">
      <button
        onClick={() => {
          if (count > 1) setCount(count - 1);
        }}
        className="count-button minus-button"
      >
        -
      </button>
      <div>{count}</div>
      <button
        onClick={() => setCount(count + 1)}
        className="count-button plus-button"
      >
        +
      </button>
    </div>
  );
}
