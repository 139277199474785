import React from "react";
import logo from "../assets/logo.png";
import MobileBurgerButton from "./MobileBurgerButton";

export default function Header() {
  return (
    <div className="pin-heading">
      <div className="main-heading">
        <div
          className="container-box"
          style={{
            height: "auto",
            width: "10%",
            minWidth: "110px",
          }}
        >
          <a href="/">
            <img src={logo} alt="brand-logo" />
          </a>
        </div>
      </div>
      <div className="mobile-heading">
        <div style={{ width: "5%" }}></div>
        <MobileBurgerButton />
        <div style={{ width: "3%" }}></div>
        <div style={{ width: "100px", marginTop: "6px" }}>
          <img src={logo} alt="company logo" />
        </div>
      </div>
    </div>
  );
}
