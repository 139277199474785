import React, { useCallback } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { commonMethods, getAjaxRequest } from "../common";
import { message } from "../config";
import { addNotification } from "../store/notificationSlice";
import CollapsibleWidget from "./CollapsibleWidget";
import ConfirmationPopup from "./ConfirmationPopup";

export default function TableWidget({
  tableDetails,
  reRender,
  type,
  tableClick,
}) {
  const [tableSelected, setTableSelected] = useState(null);
  const [popupType, setPopupType] = useState("");

  const {
    register: createRegister,
    getValues: createGetValues,
    reset: createReset,
    trigger: createTrigger,
    formState: { errors: createErrors, isValid: createIsValid },
  } = useForm({ mode: "onBlur" });

  const {
    register: updateRegister,
    getValues: updateGetValues,
    reset: updateReset,
    trigger: updateTrigger,
    setValue: updateSetValue,
    formState: { errors: updateErrors, isValid: updateIsValid },
  } = useForm({ mode: "onBlur" });

  const dispatch = useDispatch();
  const reRenderWidget = () => {
    reRender();
    setTableSelected(null);
  };
  const getNumberOfTables = (i) => {
    const result = [];
    for (let ind = 1; ind <= i; ind++)
      result.push(
        <div
          key={ind}
          onClick={() =>
            tableClick &&
            tableClick({ tableCategory: tableSelected, tableNumber: ind.toString() })
          }
          className="table-category container-box font-4 clickable"
        >
          {ind}
        </div>
      );
    return result;
  };
  const createTableForm = (
    <div>
      <h4>Create New Category</h4>
      <div className="form-field min-content">
        <label>Table Category:</label>
        <input
          {...createRegister("category", { required: true })}
          type="text"
          placeholder="Enter Table Category"
          className="input-field medium-input"
        />
        {createErrors.category && (
          <div className="error-message">Please Enter Table Category</div>
        )}
      </div>
      <div className="form-field min-content">
        <label>Table Count:</label>
        <input
          {...createRegister("count", { required: true })}
          type="number"
          placeholder="Enter Table Count"
          className="input-field medium-input"
        />
        {createErrors.count && (
          <div className="error-message">Please Enter Table Count</div>
        )}
      </div>
    </div>
  );
  const updateTableForm = (
    <div>
      <h4>Update Table Category {tableSelected?.category}</h4>
      <div className="form-field min-content">
        <label>Table Category:</label>
        <input
          {...updateRegister("category", { required: true })}
          type="text"
          placeholder="Enter Table Category"
          className="input-field medium-input"
        />
        {updateErrors.category && (
          <div className="error-message">Please Enter Table Category</div>
        )}
      </div>
      <div className="form-field min-content">
        <label>Table Count:</label>
        <input
          {...updateRegister("count", { required: true })}
          type="number"
          placeholder="Enter Table Count"
          className="input-field medium-input"
        />
        {updateErrors.count && (
          <div className="error-message">Please Enter Table Count</div>
        )}
      </div>
    </div>
  );
  const removeTableBody = (
    <div className="text-center">
      The Table category and all it's corresponding tables will be removed from
      record, are you sure to continue?
    </div>
  );
  const getPopupConfig = () => {
    switch (popupType) {
      case "createTable":
        return {
          body: createTableForm,
          confirmCall: async () => {
            createTrigger();
            if (!createIsValid) return;
            try {
              await getAjaxRequest("addNewTableCategory", createGetValues());
              dispatch(
                addNotification({
                  type: "success",
                  message: "Table Category Added Successfully",
                })
              );
              setPopupType("");
              reRenderWidget();
            } catch (error) {
              console.log(error);
              dispatch(
                addNotification({
                  type: "error",
                  message: message.someErrorOccurreld,
                })
              );
            }
            createReset();
          },
          closePopup: () => {
            setPopupType(() => {
              createReset();
              return "";
            });
          },
          customClose: true,
        };
      case "removeTable":
        return {
          body: removeTableBody,
          confirmCall: async () => {
            try {
              await getAjaxRequest("deleteTableCategory", {
                tableCategoryId: tableSelected._id,
              });
              dispatch(
                addNotification({
                  type: "success",
                  message: "Table Category Removed Successfully",
                })
              );
              setTableSelected(null);
              reRenderWidget();
            } catch (error) {
              console.log(error);
              dispatch(
                addNotification({
                  type: "error",
                  message: message.someErrorOccurred,
                })
              );
            }
          },
          closePopup: () => setPopupType(""),
        };
      case "updateTable":
        return {
          body: updateTableForm,
          confirmCall: async () => {
            updateTrigger();
            if (!updateIsValid) return;
            try {
              await getAjaxRequest("updateTableCategory", updateGetValues());
              dispatch(
                addNotification({
                  type: "success",
                  message: "Table Category Updated Successfully",
                })
              );
              reRenderWidget();
              setPopupType("");
            } catch (error) {
              console.log(error);
              dispatch(
                addNotification({
                  type: "error",
                  message: message.someErrorOccurred,
                })
              );
            }
            updateReset();
          },
          closePopup: () => {
            setPopupType(() => {
              updateReset();
              return "";
            });
          },
          customClose: true,
        };
      default:
        return null;
    }
  };

  const getConfirmPopup = () => {
    const popupConfig = getPopupConfig();
    return (
      popupConfig && (
        <ConfirmationPopup
          closePopup={popupConfig.closePopup}
          confirmCall={popupConfig.confirmCall}
          customClose={popupConfig.customClose}
        >
          {popupConfig.body}
        </ConfirmationPopup>
      )
    );
  };

  const setUpdateForm = (obj) => {
    updateSetValue("_id", obj._id);
    updateSetValue("category", obj.category);
    updateSetValue("count", obj.count);
  };

  const tableCategoryClick = useCallback((tableCategory) => {
    if (tableSelected === tableCategory) setTableSelected(null);
    else setTableSelected(tableCategory);
  }, [tableSelected]);

  return (
    <>
      {getConfirmPopup()}
      <h4>Table Categories</h4>
      <div className="full-width">
        <div className="flex-wrap vertical-center table-categories-container">
          {type === "businessAdminDashboard" && (
            <div
              onClick={() => {
                setPopupType("createTable");
              }}
              className="table-category add-table-category container-box font-4 clickable"
            >
              +
            </div>
          )}
          {tableDetails.map((tableCategory, ind) => (
            <div
              onClick={() => tableCategoryClick(tableCategory)}
              key={tableCategory._id}
              className={`table-category container-box font-4 clickable ${
                tableCategory._id === tableSelected?._id
                  ? "selected"
                  : ""
              }`}
            >
              {tableCategory.category}
            </div>
          ))}
        </div>
        <div className="gap-5"></div>
        {type === "businessAdminDashboard" && tableSelected ? (
          <>
            <div>
              <button
                style={{ margin: "0px 5px" }}
                className="double-line-button red-button font-3"
                onClick={() => setPopupType("removeTable")}
              >
                Remove Category
              </button>
              <button
                onClick={() => {
                  setUpdateForm(tableSelected);
                  setPopupType("updateTable");
                }}
                className="double-line-button yellow-button font-3"
              >
                Modify Table Category
              </button>
            </div>
            <div className="gap"></div>
          </>
        ) : null}
        <div>
          <CollapsibleWidget open={tableSelected}>
            <div>
              <h5>Tables</h5>
              <div className="gap-5"></div>
              <div className="flex-wrap">
                {getNumberOfTables(tableSelected?.count)}
              </div>
            </div>
          </CollapsibleWidget>
        </div>
      </div>
    </>
  );
}
