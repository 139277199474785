import React, { useCallback, useState } from "react";

export default function DebounceInput(props) {
  const [inputValue, setInputValue] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);

  const handleInputChange = useCallback(
    (event) => {
      setInputValue(event.target.value);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      setTimeoutId(
        setTimeout(() => {
          props.onChange(event.target.value);
          setTimeoutId(null);
        }, 700)
      );
    },
    [props, timeoutId]
  );

  return (
    <input
      type={props.type}
      className={props.className}
      placeholder={props.placeholder}
      value={inputValue}
      onChange={handleInputChange}
    />
  );
}
