import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { commonMethods, getAjaxRequest } from "../common";
import Spinner from "../commonComponents/Spinner";
import config, { message } from "../config";
import { addNotification } from "../store/notificationSlice";
import { addOrder } from "../store/orderButtonSlice";
import ConfirmationPopup from "./ConfirmationPopup";
import Counter from "./Counter";
import ToggleCheckbox from "./ToggleCheckbox";

export default function DishDetailsPopup({ details, type, dishDetailsCallback }) {
  const [count, setCount] = useState(1);
  const [activeDish, setActiveDish] = useState(details.active);
  const [showSpinner, setShowSpinner] = useState(false);
  const [removeDishConfirm, setRemoveDishConfirm] = useState(false);
  const dispatch = useDispatch();

  const addToOrder = function () {
    dispatch(addOrder({ ...details, count }));
    dispatch(
      addNotification({
        type: "info",
        message: "Added to Order List. Please Confirm to Place Your Order",
      })
    );
    dishDetailsCallback('close');
  };

  const removeDish = async () => {
    try {
      await getAjaxRequest(
        "removeDish",
        {
          dishCategoryId: details.dishCategoryId,
          dishId: details._id,
        },
        setShowSpinner
      );
      dispatch(
        addNotification({
          type: "success",
          message: "Dish Removed Successfully",
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        addNotification({
          type: "error",
          message: message.someErrorOccurred,
        })
      );
    } finally {
      dishDetailsCallback('reRender');
    }
  };

  const toggleDishStatus = async (event) => {
    try {
      await getAjaxRequest(
        "toggleDishStatus",
        {
          dishCategoryId: details.dishCategoryId,
          dishId: details._id,
        },
        setShowSpinner
      );
      dispatch(
        addNotification({
          type: "success",
          message: event.target.checked
            ? "Current Dish is Disabled, It won't Appear in User's Menu"
            : "Current Dish is Enabled, It will Appear in User's Menu",
        })
      );
      setActiveDish((val) => !val);
    } catch (error) {
      console.log(error);
      dispatch(
        addNotification({
          type: "error",
          message: message.someErrorOccurred,
        })
      );
    }
  };

  return (
    <Spinner showSpinner={showSpinner}>
      {removeDishConfirm && (
        <ConfirmationPopup
          confirmCall={removeDish}
          closePopup={() => setRemoveDishConfirm(false)}
        >
          <div className="font-3 text-center">
            Are you sure, you want to remove this dish? You can disable it also.
          </div>
        </ConfirmationPopup>
      )}
      <div className="full-width">
        <div
          style={{
            backgroundImage: `url(${config.baseResources}/${details.image})`,
          }}
          className="container-box cover-image popup-dish-cover"
        ></div>
        <div style={{ height: "5px" }}></div>
        <div className="padding-5">
          <h4>{details.name}</h4>
          <div className="gap-5"></div>
          <div className="vertical-center">
            <h3>
              {commonMethods.getCurrency(details.currency)}
              {details.discountedPrice}
            </h3>
            <div className="gap-x-5"></div>
            {details.price ? (
              <div className="price-strike-through">
                {commonMethods.getCurrency(details.currency)}
                {details.price}
              </div>
            ) : null}
            <div className="gap-x-5"></div>
          </div>
          <div className="gap-5"></div>
          {(type !== "adminDashboard" && (
            <div className="align-center">
              <Counter count={count} setCount={setCount}></Counter>
              <div className="gap-10"></div>
              <button onClick={addToOrder} className="double-line-button blue-button">
                Add to Order List
              </button>
            </div>
          )) || (
            <div>
              <div className="gap-5"></div>
              <div className="container-box">
                <button
                  onClick={() => setRemoveDishConfirm(true)}
                  className="double-line-button red-button font-3"
                >
                  Remove Dish
                </button>
                <div className="gap-x-10"></div>
                <div className="same-line">
                  <label>Active Status:</label>
                  <ToggleCheckbox
                    onChange={toggleDishStatus}
                    value={activeDish}
                  />
                </div>
              </div>
              <div className="gap-5"></div>
            </div>
          )}
          <div className="gap-10"></div>
          <div className="font-2">{details.description}</div>
          <div className="gap-10"></div>
          <div className="gap-10"></div>
        </div>
      </div>
    </Spinner>
  );
}
