import { useEffect, useState } from "react";
import { setIsMobileScreen, setScreenWidth } from "../store/generalSlice";
import { useDispatch } from "react-redux";

// not used will be removed once new hook comes
// It will throw a warning the dependancy array should be empty for proper working
// as we are passing anonymous arrow function to this hook, it needs to mentioned inside useCallback hook
// to fix this warning
export function useBeforeMount(fn) {
  const [mount, setMount] = useState(false);
  useEffect(() => {
    const cleanup = fn();
    setMount(true);
    return cleanup;
  }, []);
  return [mount];
}

export function useRegisterConfigs() {
  const dispatch = useDispatch();
  useEffect(() => {
    const resizeFn = () => {
      dispatch(setScreenWidth(window.innerWidth));
      if (window.innerWidth > 720) dispatch(setIsMobileScreen(false));
      else dispatch(setIsMobileScreen(true));
    };
    window.addEventListener("resize", resizeFn);
    if (window.innerWidth > 720) dispatch(setIsMobileScreen(false));
    else dispatch(setIsMobileScreen(true));
    return () => window.removeEventListener("resize", resizeFn);
  }, [dispatch]);
}
