import Sidebar from "./Sidebar";

export default function DesktopSidebar() {
  return (
    <div className="desktopSidebarContainer">
      <div className="desktopSidebar full-width">
      <Sidebar />
      </div>
    </div>
  );
}
