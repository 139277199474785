import React, { useCallback, useState } from "react";
import { getAjaxRequest } from "../common";
import { useDispatch } from "react-redux";
import { addNotification } from "../store/notificationSlice";

export default function RegisterRestaurant() {
  const [formValue, setFormValue] = useState({});
  const dispatch = useDispatch();
  const handleChangeEvent = (e) => {
    setFormValue({
      ...formValue,
      [e.target.name]: e.target.value,
    });
  };
  const submitForm = useCallback(async () => {
    try {
      const data = {
        ownerEmail: formValue.ownerEmail,
        adminEmailList: formValue.adminEmailList
          ? formValue.adminEmailList.split(",")
          : [],
        workerEmailList: formValue.workerEmailList
          ? formValue.workerEmailList.split(",")
          : [],
      };
      await getAjaxRequest("setOrUpdateRestaurant", data);
      dispatch(
        addNotification({
          type: "success",
          message: "Successfully created restaurant",
        })
      );
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, formValue]);
  return (
    <div className="full-width container-box">
      <div className="default-width">
        <div className="form-field">
          <label>Owner Email</label>
          <input
            type="text"
            placeholder="One single email"
            className="input-field medium-input"
            onChange={handleChangeEvent}
            name="ownerEmail"
          />
        </div>
        <div className="form-field">
          <label>Admin Email</label>
          <input
            type="text"
            placeholder="Multiple email comma separated"
            className="input-field medium-input"
            onChange={handleChangeEvent}
            name="adminEmailList"
          />
        </div>
        <div className="form-field">
          <label>Worker Email</label>
          <input
            type="text"
            placeholder="Multiple email comma separated"
            className="input-field medium-input"
            onChange={handleChangeEvent}
            name="workerEmailList"
          />
        </div>
        <button
          onClick={submitForm}
          className="double-line-button green-button font-3"
        >
          Submit
        </button>
      </div>
    </div>
  );
}
