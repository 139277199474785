import React from "react";

export default function Spinner({ children, showSpinner, showTextOnly, takeHeight }) {
  return (
    <div className="spinnerContainer" style={{minHeight: showSpinner && takeHeight ? "200px" : "0px"}}>
      {children}
      {showSpinner ? (
        <div className="spinner container-box">
          <div className="align-center">
            {!showTextOnly ? (
              <>
                <div style={{ width: "30px" }}>
                  <img src="/files/misc/loading-gif.gif" alt="loading" />
                </div>
                <div className="gap-y"></div>
              </>
            ) : null}
            <div className="font-1">Loading ...</div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
