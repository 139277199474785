import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { commonMethods, getAjaxRequest } from "../common";
import { useCallback } from "react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmationPopup from "./ConfirmationPopup";
import { billingCenterSocket } from "../socket";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "../store/notificationSlice";
import SearchInputField from "../commonComponents/SearchInputField";
import { message } from "../config";

export default function BillingComponent({
  tableBillOrderList,
  billingItemList,
  tableSelected,
  closePopup,
  restaurantDetails,
  type,
}) {
  const [mockOrderList, setmockOrderList] = useState([]);
  const [mockBillingItemList, setMockBillingItemList] = useState([]);
  const [popupType, setPopupType] = useState("");
  const [emailUserList, setEmailUserList] = useState([]);
  const [sendMailButtonDisabled, setSendMailButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    setmockOrderList(commonMethods.getDeepCopy(tableBillOrderList));
  }, [tableBillOrderList]);

  useEffect(() => {
    setMockBillingItemList(commonMethods.getDeepCopy(billingItemList));
  }, [billingItemList]);

  const removeOrder = useCallback(
    (i, j) => {
      const orderObject = mockOrderList.find((item) => item._id === i._id);
      const orderIndex = orderObject.orderDishesList.findIndex(
        (item2) => item2._id === j._id
      );
      orderObject.orderDishesList.splice(orderIndex, 1);
      setmockOrderList([...mockOrderList]);
    },
    [mockOrderList]
  );

  const tableBillOrderTotal = useMemo(() => {
    return mockOrderList.reduce(
      (acc, curr) =>
        acc +
        curr.orderDishesList.reduce(
          (acc, curr) => acc + curr.eachCost * curr.quantity,
          0
        ),
      0
    );
  }, [mockOrderList]);

  const getChargedAmount = useCallback((totalCost, chargeObject) => {
    const amount =
      chargeObject.amountType === "Percentage"
        ? totalCost * (chargeObject.amount / 100)
        : Number(chargeObject.amount);
    return {
      text: `${chargeObject.type === "Discount" ? "-" : "+"}${amount.toFixed(
        2
      )}`,
      value: amount * (chargeObject.type === "Discount" ? -1 : 1),
    };
  }, []);

  const grandTotal = useMemo(() => {
    const temp =
      mockBillingItemList.length &&
      mockBillingItemList
        .filter((i) => i.active)
        .reduce((acc, curr) => {
          return acc + getChargedAmount(tableBillOrderTotal, curr).value;
        }, 0);
    return (tableBillOrderTotal + temp).toFixed(2);
  }, [mockBillingItemList, tableBillOrderTotal, getChargedAmount]);

  const addOrder = useCallback(() => {
    navigate(
      `/restaurant/${restaurantDetails._id}/${tableSelected.tableCategory._id}/${tableSelected.tableNumber}`
    );
  }, [restaurantDetails, navigate, tableSelected]);

  const isMobile = useSelector((state) => state.general.isMobileScreen);

  const printOrder = useCallback(() => {
    const content = document.getElementById("billContainer");
    commonMethods.printHtml(
      `<div style="padding: 10px;">${content.innerHTML}</div>`,
      isMobile
    );
  }, [isMobile]);

  const archiveOrder = useCallback(() => {
    setPopupType("confirmArchive");
  }, []);

  const confirmArchive = useCallback(() => {
    billingCenterSocket.emit(
      "archiveOrder",
      {
        orderDetails: mockOrderList,
        chargeDiscount: mockBillingItemList,
        tableCategory: mockOrderList[0].tableCategory,
        tableNo: mockOrderList[0].tableNo,
        totalCost: grandTotal,
      },
      () => {
        dispatch(
          addNotification({
            type: "success",
            message: "Orders are archived. Table is ready to use.",
          })
        );
      }
    );
  }, [mockOrderList, mockBillingItemList, grandTotal, dispatch]);

  const getPopup = () => {
    switch (popupType) {
      case "confirmArchive":
        return (
          <ConfirmationPopup
            confirmCall={confirmArchive}
            closePopup={closePopup}
          >
            <div className="container-box font-3">
              <div>{`Archive orders for ${tableSelected.tableCategory.category} ${tableSelected.tableNumber} ?`}</div>
            </div>
          </ConfirmationPopup>
        );
      default:
        break;
    }
  };

  const [searchUserList, setSearchUserList] = useState([]);

  const searchUser = useCallback(
    async (v) => {
      if (!v) return;
      try {
        const data = await getAjaxRequest("searchUser", { value: v });
        data.data.forEach((i) => (i.label = i.email));
        setSearchUserList(data.data);
      } catch (error) {
        console.log(error);
        dispatch(
          addNotification({
            type: "error",
            message: message.someErrorOccurred,
          })
        );
      }
    },
    [dispatch]
  );

  const billlingOrderList = useMemo(() => {
    const temp = [];
    mockOrderList.forEach((i) => {
      i.orderDishesList.forEach((j) =>
        temp.push({
          dishName: j.dishName,
          quantity: j.quantity,
          totalCost: j.totalCost,
        })
      );
    });
    return temp;
  }, [mockOrderList]);

  const createMailHtmlString = useCallback(() => {
    const preString = `<div><h4>Greetings**</h4><p>Thank you for having a great time at ${restaurantDetails.restaurantName}. Here attached your bill for the day.</p><div style="font-family:monospace;border:1px solid grey;border-radius:5px;padding:20px;width:max-content">`;
    const billingString = billlingOrderList
      .map((i) => {
        return `<div style="display:flex"><div style="margin-right:20px">${i.dishName} x ${i.quantity}</div><div>${i.totalCost}</div></div>`;
      })
      .join("");
    const dashedString = '<div style="border-bottom:1px dashed #000"></div>';
    const subtotalString = `<div style="display:flex"><div style="margin-right:20px">Subtotal</div><div>${tableBillOrderTotal}</div></div>`;
    const chargesString = mockBillingItemList
      .map((i) => {
        return i.active ? `<div style="display:flex"><div style="margin-right:20px">${
          i.name
        }</div><div>${
          getChargedAmount(tableBillOrderTotal, i).text
        }</div></div>` : '';
      })
      .join("");
    const grandTotalString = `<div style="display:flex"><div style="margin-right:20px">Grand Total</div><div>${grandTotal}</div></div>`;
    const postString = `</div><p>We are excited to have you again.</p><div style="font-weight:700">Regards,</div><div>${restaurantDetails.restaurantName}</div></div>`;
    return `${preString}${billingString}${dashedString}${subtotalString}${chargesString}${grandTotalString}${postString}`;
  }, [
    restaurantDetails,
    grandTotal,
    getChargedAmount,
    billlingOrderList,
    mockBillingItemList,
    tableBillOrderTotal,
  ]);

  const sendEmail = useCallback(async () => {
    if (!emailUserList.length)
      dispatch(
        addNotification({
          type: "error",
          message: "No email selected",
        })
      );
    setSendMailButtonDisabled(true);

    try {
      const emailList = emailUserList.map((i) => i.email);
      await getAjaxRequest("sendEmailBill", {
        emailList,
        billBody: createMailHtmlString(),
      });
      dispatch(
        addNotification({
          type: "success",
          message: "Email sent successfully",
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        addNotification({
          type: "error",
          message: message.someErrorOccurred,
        })
      );
    } finally {
      setSendMailButtonDisabled(false);
    }
  }, [dispatch, emailUserList, createMailHtmlString]);

  return (
    <div>
      {getPopup()}
      {!!mockOrderList.length && (
        <div id="billContainer">
          <div className="billContainer">
            {mockOrderList.map((i) => (
              <React.Fragment key={i._id}>
                {i.orderDishesList.map((j) => (
                  <div
                    key={j._id}
                    className="billOrderItem font-2 align-corner"
                  >
                    <div>{`${j.dishName} x ${j.quantity}`}</div>
                    <div className="same-line">
                      <div>{j.totalCost}</div>
                      <div className="gap-x-5"></div>
                      <button
                        className="no-print"
                        onClick={() => {
                          removeOrder(i, j);
                        }}
                        style={{
                          fontSize: "20px",
                          color: "#d32f2f",
                        }}
                      >
                        &#x2716;
                      </button>
                    </div>
                  </div>
                ))}
              </React.Fragment>
            ))}
            <div className="dotted-line"></div>
            <div className="billOrderItem font-2 align-corner">
              <div>Subtotal</div>
              <div>{tableBillOrderTotal}</div>
            </div>
            {mockBillingItemList.map((i, index) => (
              <div
                key={index}
                className={`billOrderItem font-2 align-corner ${
                  i.active ? "" : "no-print"
                }`}
              >
                <div className="same-line">
                  <div>
                    <input
                      className="no-print"
                      defaultChecked={i.active}
                      type="checkbox"
                      onChange={() => {
                        i.active = !i.active;
                        setMockBillingItemList((data) => [...data]);
                      }}
                    />
                  </div>
                  <div className="gap-x-5"></div>
                  <div>{i.name}</div>
                </div>
                <div>{getChargedAmount(tableBillOrderTotal, i).text}</div>
              </div>
            ))}
            <div className="billOrderItem font-2 align-corner">
              <div>Grand Total</div>
              <div>{grandTotal}</div>
            </div>
          </div>
        </div>
      )}
      <div className="gap"></div>
      <div className="align-right">
        {type === "billingCenter" && (
          <button
            onClick={addOrder}
            className="double-line-button blue-button font-3"
          >
            Add Order
          </button>
        )}
        <div className="gap-x-5"></div>
        {!!mockOrderList?.length &&
          ["billingCenter", "archiveOrder"].includes(type) && (
            <button
              onClick={printOrder}
              className="double-line-button green-button font-3"
            >
              Print Bill
            </button>
          )}
        <div className="gap-x-5"></div>
        {!!mockOrderList?.length && type === "billingCenter" && (
          <button
            onClick={archiveOrder}
            className="double-line-button red-button font-3"
          >
            Archive Order
          </button>
        )}
      </div>
      <div className="gap"></div>
      <div>
        <h4>Email Invoice:</h4>
        <div className="gap-5"></div>
        <div className="flex-wrap">
          {emailUserList.map((i) => (
            <div className="email-badge font-1 same-line" key={i._id}>
              <div>{i.email}</div>
              <div
                style={{
                  margin: "0px 7px",
                  borderRight: "1px solid white",
                  height: "14px",
                }}
              ></div>
              <div
                className="clickable"
                onClick={() => {
                  const temp = [...emailUserList];
                  const ind = temp.indexOf(i);
                  temp.splice(ind, 1);
                  setEmailUserList(temp);
                }}
              >
                &#x2a09;
              </div>
            </div>
          ))}
        </div>
        <div className="same-line">
          <div style={{ maxWidth: "60%" }}>
            <SearchInputField
              inputProps={{
                placeholder: "Search name or email",
                type: "text",
                className: "input-field medium-input full-width",
                onChange: searchUser,
              }}
              inputType="debounce"
              suggestionList={searchUserList}
              onSelection={(v) => {
                if (!emailUserList.includes(v))
                  setEmailUserList([...emailUserList, v]);
              }}
            />
          </div>
          <div className="gap-x"></div>
          <button
            onClick={sendEmail}
            className={`double-line-button green-button font-3 ${
              sendMailButtonDisabled ? "button-disabled" : ""
            }`}
          >
            Send Email
          </button>
        </div>
      </div>
    </div>
  );
}
