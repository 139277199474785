import { configureStore } from "@reduxjs/toolkit";
import generalSlice from "./generalSlice";
import restaurantLandingSlice from "./restaurantLanding";
import orderButtonSlice from "./orderButtonSlice";
import notificationSlice from "./notificationSlice";
import sidebarSlice from "./sidebarSlice";
import profileSlice from "./profileSlice";
import restaurantBackendSlice from "./restaurantBackendSlice";
import billingCenterSlice from "./billingCenterSlice";



export default configureStore({
    reducer: {
        general: generalSlice,
        restaurantLanding: restaurantLandingSlice,
        orderButton: orderButtonSlice,
        notification: notificationSlice,
        sidebar: sidebarSlice,
        profile: profileSlice,
        restaurantBackend: restaurantBackendSlice,
        billingCenter: billingCenterSlice
    }
})