import { createSlice } from "@reduxjs/toolkit";

const restaurantBackendSlice = createSlice({
    name: 'restaurantBackend',
    initialState: {
        restaurantDetails: null
    },
    reducers: {
        setRestaurantDetails(state, { payload }) {
            state.restaurantDetails = payload;
        }
    }
});

export default restaurantBackendSlice.reducer;
export const { setRestaurantDetails } = restaurantBackendSlice.actions;