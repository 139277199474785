import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getAjaxRequest } from "../common";
import FileUploadField from "../commonComponents/FileUploadField";
import { addNotification } from "../store/notificationSlice";

export default function RegisterBusiness() {
  // this widget is for quickly register a business in database during acquisition
  const dispatch = useDispatch();
  const [formValue, setFormValue] = useState({});
  const handleChangeEvent = (e) => {
    setFormValue({
      ...formValue,
      [e.target.name]: e.target.value
    })
  };
  const formSubmit = async () => {
    const options = {
      ...formValue,
      businessRegistrationDoc: formValue.businessRegistrationDoc?.map(item => item.fileName),
      businessOwnerIdDoc: formValue.businessOwnerIdDoc?.map(item => item.fileName),
      otherDoc: formValue.otherDoc?.map(item => item.fileName),
    };
    try {
      await getAjaxRequest('acquireUserRegister', options);
      dispatch(
        addNotification({
          type: "success",
          message: "User Registered Successfully",
        })
      );
    } catch (error) {
      dispatch(
        addNotification({
          type: "error",
          message: "Something Went Wrong",
        })
      );
    }

  }
  return (
    <>
      <div className="full-width container-box">
        <div className="default-width">
          <div className="form-field">
            <label>Business Name:</label>
            <input
              type="text"
              placeholder="Enter Business Name"
              className="input-field medium-input"
              name="businessName"
              onChange={handleChangeEvent}
            />
          </div>
          <div className="form-field">
            <label>Business Owner Name:</label>
            <input
              type="text"
              placeholder="Enter Business Owner Name"
              className="input-field medium-input"
              name="businessOwnerName"
              onChange={handleChangeEvent}
            />
          </div>
          <div className="form-field">
            <label>Business Registered ID (GST):</label>
            <input
              type="text"
              placeholder="Enter Business Registered ID"
              className="input-field medium-input"
              name="businessRegisteredId"
              onChange={handleChangeEvent}
            />
          </div>
          <div className="form-field">
            <label>Owner Identity Number (Aadhar/ PAN/ Voter ID):</label>
            <input
              type="text"
              placeholder="Enter Owner ID Number"
              className="input-field medium-input"
              name="businessOwnerId"
              onChange={handleChangeEvent}
            />
          </div>
          <div className="form-field">
            <FileUploadField
              name="businessRegistrationDoc"
              label="Upload Business Registration Document"
              changeEvent={handleChangeEvent}
            />
          </div>
          <div className="form-field">
            <FileUploadField
              name="businessOwnerIdDoc"
              label="Upload Business Owner ID"
              changeEvent={handleChangeEvent}
            />
          </div>
          <div className="form-field">
            <FileUploadField
              name="otherDoc"
              label="Other Supporting Document"
              changeEvent={handleChangeEvent}
            />
          </div>
          <div>
            <button onClick={formSubmit} className="order-button button-small font-2">Submit</button>
          </div>
        </div>
      </div>
    </>
  );
}
