import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { commonMethods } from "../common";
import config from "../config";
import { removeNavigationList, setNavigationList } from "../store/sidebarSlice";
import DishDetailsPopup from "./DishDetailsPopup";
import Popup from "./Popup";
import { useRef } from "react";

export default function DishesList({
  allDishDetails,
  type,
  dishCategoryClick,
  dishClick,
  reRender,
}) {
  const [dishSelected, setDishSelected] = useState(null);
  const componentName = useRef("dishList");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setNavigationList({
        navList: allDishDetails
          ? allDishDetails.dishes.map((i) => ({
              scroll: true,
              url: `#dish-${i._id}`,
              text: i.categoryName,
              type: "sub",
            }))
          : [],
        topNav: "Dish Categories",
        componentName: componentName.current
      })
    );
    return () => dispatch(removeNavigationList(componentName.current));
  }, [allDishDetails, dispatch]);
  const dishDetailsCallback = (message) => {
    switch (message) {
      case "reRender":
        setDishSelected(null);
        reRender();
        break;
      case "close":
        setDishSelected(null);
        break;
      default:
        setDishSelected(null);
        break;
    }
  };
  return (
    <div>
      {dishSelected && (
        <Popup
          closePopup={() => {
            setDishSelected(null);
            if (reRender) reRender();
          }}
        >
          <DishDetailsPopup
            dishDetailsCallback={dishDetailsCallback}
            details={dishSelected}
            type={type}
          />
        </Popup>
      )}
      {allDishDetails?.dishes.map((dishType, ind) => {
        return (
          <div key={dishType._id} className="full-width">
            <div className="align-center">
              <div className="same-line full-width">
                <h4 id={`dish-${dishType._id}`}>{dishType.categoryName}</h4>
                <div className="gap-x-10"></div>
                {type === "adminDashboard" && (
                  <div className="same-line">
                    <button
                      onClick={() => dishCategoryClick("create", dishType)}
                      className="icon-background plus-icon-button"
                    ></button>
                    <div className="gap-x-5"></div>
                    <button
                      onClick={() => dishCategoryClick("update", dishType)}
                      className="icon-background pencil-icon-button"
                    ></button>
                    <div className="gap-x-5"></div>
                    <button
                      onClick={() => dishCategoryClick("delete", dishType)}
                      className="icon-background bin-icon-button"
                    ></button>
                  </div>
                )}
              </div>
              <div className="default-width flex-wrap container-box">
                {dishType.dishList.map((dish, ind) => (
                  (type === 'adminDashboard' || dish.active) && <div
                    key={dish._id}
                    className={`restaurant-dish clickable relative ${
                      dish.active ? "" : "inactive-dish"
                    }`}
                  >
                    <div
                      onClick={() =>
                        setDishSelected({
                          ...dish,
                          currency: allDishDetails.currency,
                          dishCategoryId: dishType._id,
                        })
                      }
                    >
                      <div
                        className="full-width dish-image cover-image"
                        style={{
                          backgroundImage: `url(${config.baseResources}/${dish.image})`,
                        }}
                      ></div>
                      <div className="padding-5">
                        <div className="font-4 text-right">{dish.name}</div>
                        <div className="font-0 dish-one-liner">
                          {dish.description}
                        </div>
                        <div className="text-right font-5">
                          {commonMethods.getCurrency(allDishDetails.currency)}
                          {dish.discountedPrice}
                        </div>
                      </div>
                    </div>
                    {type === "adminDashboard" && (
                      <button
                        onClick={() => dishClick("update", dish, dishType)}
                        className="hover-edit-button"
                      >
                        <div className="icon-background pencil-icon-button small-icon"></div>
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="gap"></div>
          </div>
        );
      })}
    </div>
  );
}
