import React from "react";

export default function DefaultPage() {
  return (
    <div>
      <div className="coverImageContainer">
        <div className="coverImageText">
          <h2>Experience new way of dine in, Biztro the all in one app.</h2>
        </div>
        <div className="coverImage">
          <div className="container-box" style={{ height: "100%" }}>
            <img src="/files/misc/welcome-cover.png" alt="" />
          </div>
        </div>
      </div>
      <div className="gap-10"></div>
      <div
        className="coverImageContainer"
        style={{ backgroundImage: "linear-gradient(to left, #0799ff, white)" }}
      >
        <div className="coverImage" style={{ left: "0px", right: "unset" }}>
          <div className="container-box" style={{ height: "100%" }}>
            <img src="/files/misc/pagoda-cover.png" alt="" />
          </div>
        </div>
        <div className="coverImageText" style={{ right: "5px", left: "unset" }}>
          <h2>Going out on a Date? Just Biztro.</h2>
        </div>
      </div>
      <div className="gap-10"></div>
      <div className="coverImageContainer" style={{backgroundImage: 'linear-gradient(to right, #07ddef, white)'}}>
        <div className="coverImageText">
          <h2>Why scrolling, while you can try another dish.</h2>
        </div>
        <div className="coverImage">
          <div className="container-box" style={{ height: "100%" }}>
            <img src="/files/misc/pizza-shop-cover.png" alt="" />
          </div>
        </div>
      </div>
      <div className="gap-10"></div>
      <div className="coverImageContainer" style={{backgroundImage: 'linear-gradient(to left, #708d00, white)'}}>
        <div className="coverImage" style={{ left: "0px", right: "unset" }}>
          <div className="container-box" style={{ height: "100%" }}>
            <img src="/files/misc/vatican-cover.png" alt="" />
          </div>
        </div>
        <div className="coverImageText" style={{ right: "5px", left: "unset" }}>
          <h2>Why did the pasta go to the fancy restaurant? Because it wanted to get saucy!</h2>
        </div>
      </div>

      {/* <div
        style={{
          backgroundImage: "linear-gradient(to left, #0799ff, white)",
          borderRadius: "5px",
          display: "flex",
          overflow: "hidden",
        }}
      >
        <div className="container-box" style={{ height: "310px" }}>
          <img src="/files/misc/pagoda-cover.png" alt="" />
        </div>
      </div>
      <div className="gap-10"></div>
      <div
        className="align-right"
        style={{
          backgroundImage: "linear-gradient(to right, #07ddef, white)",
          overflow: "hidden",
          borderRadius: "5px",
        }}
      >
        <div className="container-box" style={{ height: "310px" }}>
          <img src="/files/misc/pizza-shop-cover.png" alt="" />
        </div>
      </div>
      <div className="gap-10"></div>
      <div
        style={{
          display: "flex",
          backgroundImage: "linear-gradient(to left, #708d00, white)",
          overflow: "hidden",
          borderRadius: "5px",
        }}
      >
        <div className="container-box" style={{ height: "310px" }}>
          <img src="/files/misc/vatican-cover.png" alt="" />
        </div>
      </div> */}
      <div className="gap"></div>
      <div className="gap"></div>
      <div className="gap"></div>
      <div className="gap"></div>
    </div>
  );
}
