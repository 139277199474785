import React from "react";

export default function PrivacyPolicy() {
  return (
    <div>
      <h4>Privacy Policy</h4>
      <div className="gap-5"></div>
      <p>
        <strong>1. Information We Collect:</strong>
      </p>
      <p>
        We may collect personal information such as names, email addresses, and
        profile pictures when users log in through Google OAuth.
      </p>

      <p>
        <strong>2. How We Use Your Information:</strong>
      </p>
      <p>
        The information collected is used to create and manage user accounts. We
        may use your email address to send important updates and notifications.
      </p>

      <p>
        <strong>3. Third-Party Access:</strong>
      </p>
      <p>
        We use Google OAuth for authentication. By logging in through Google,
        you grant us access to certain information in your Google account.
      </p>

      <p>
        <strong>4. Security:</strong>
      </p>
      <p>
        We implement security measures to protect your information. However, no
        method of transmission over the internet is 100% secure.
      </p>

      <p>
        <strong>5. Cookies:</strong>
      </p>
      <p>
        We may use cookies to enhance user experience. You can disable cookies
        in your browser settings.
      </p>

      <p>
        <strong>6. Data Retention:</strong>
      </p>
      <p>
        We retain user data for a period necessary to fulfill the purposes
        outlined in this Privacy Policy unless a longer retention period is
        required or permitted by law.
      </p>

      <p>
        <strong>7. User Rights:</strong>
      </p>
      <p>
        Users have the right to access, correct, or delete their personal
        information. To exercise these rights, please contact us at [your
        contact email].
      </p>

      <p>
        <strong>8. Data Sharing:</strong>
      </p>
      <p>
        We do not share your personal information with third parties except as
        required by law or with your explicit consent.
      </p>

      <p>
        <strong>9. Changes to Privacy Policy:</strong>
      </p>
      <p>
        We reserve the right to update our privacy policy as needed. Any changes
        will be communicated to users.
      </p>
    </div>
  );
}
