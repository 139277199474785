import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getAjaxRequest } from "../common";
import config from "../config";
import { addNotification } from "../store/notificationSlice";
import Spinner from "./Spinner";

export default function FileUploadField({
  name,
  label,
  changeEvent,
  singleUpload,
  fileType,
  hideUploadedFiles,
}) {
  const uploadForm = useRef();
  const [loading, setLoading] = useState(false);
  const [uploadedFileList, setUploadedFileList] = useState([]);
  useEffect(() => {
    changeEvent({ target: { value: uploadedFileList, name } });
  }, [uploadedFileList]);

  const dispatch = useDispatch();
  const fieldChange = async () => {
    try {
      const data = new FormData(uploadForm.current);
      if (
        fileType &&
        data
          .getAll("files")
          .find((i) => !fileType.includes(i.name.split(".").pop()))
      ) {
        dispatch(
          addNotification({
            type: "warning",
            message: `Only ${fileType.join(", ")} Files are Allowed`,
          })
        );
        uploadForm.current.reset();
        return;
      }
      const res = await getAjaxRequest("uploadFile", data, setLoading, true);
      setUploadedFileList([
        ...((!singleUpload && uploadedFileList) || []),
        ...res.data.files,
      ]);
      dispatch(
        addNotification({
          type: "success",
          message: "File Uploaded Successfully",
        })
      );
      uploadForm.current.reset();
    } catch (error) {
      console.log(error);
      dispatch(
        addNotification({
          type: "error",
          message: "File Upload Failed",
        })
      );
      uploadForm.current.reset();
    }
  };
  return (
    <div className="full-width">
      <form ref={uploadForm} encType="multipart/form-data">
        <Spinner showSpinner={loading} showTextOnly>
          <label className="uploadFileField" htmlFor={name}>
            <div className="font-2">{label || "Select File"}</div>
          </label>
        </Spinner>
        <input
          style={{ display: "none" }}
          type="file"
          name="files"
          id={name}
          onChange={() => fieldChange()}
          multiple={!singleUpload}
        />
      </form>
      {!hideUploadedFiles &&
        uploadedFileList.map((item, i) => (
          <div key={i} className="container-box file-item">
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={`${config.baseResources}/${item.fileName}`}
              className="file-name font-2"
            >
              {item.originalName}
            </a>
            <button
              onClick={() =>
                setUploadedFileList(
                  uploadedFileList.filter((item, ind) => i !== ind)
                )
              }
              className="file-delete"
            >
              &#10006;
            </button>
          </div>
        ))}
    </div>
  );
}
