import { io } from 'socket.io-client';
import config from './config';

const baseUrl = config.socketBaseUrl;
const actionCenterUrl = `${baseUrl}/actionCenter`;
const customerPortalUrl = `${baseUrl}/customer`;
const billingCenterUrl = `${baseUrl}/billingCenter`;

export const actionCenterSocket = io(actionCenterUrl, config.socketConfiguration);

export const customerPortalSocket = io(customerPortalUrl, config.socketConfiguration);

export const billingCenterSocket = io(billingCenterUrl, config.socketConfiguration);
